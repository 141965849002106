import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { Roll } from "@/ts/royalur/model/dice/Roll";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { PlayableGameState } from "@/ts/royalur/rules/state/PlayableGameState";
import { Move } from "@/ts/royalur/model/Move";
import { DescribePlayerNames, getDescribePlayerName } from "@/ts/royalur/rules/state/GameState";


/**
 * A game state where the game is waiting for a player to make a move.
 */
export class WaitingForMoveGameState extends PlayableGameState {
    private readonly roll: Roll;
    private readonly availableMoves: Move[];

    constructor(
        board: Board,
        lightPlayer: PlayerState,
        darkPlayer: PlayerState,
        turn: PlayerType,
        roll: Roll,
        availableMoves: Move[],
    ) {
        super(board, lightPlayer, darkPlayer, turn);
        if (roll.value() <= 0) {
            throw new Error(
                "Roll must have a value of at least 1, not " + roll.value() + ".",
            );
        }

        this.roll = roll;
        this.availableMoves = availableMoves;
    }

    getRoll(): Roll {
        return this.roll;
    }

    getAvailableMoves(): Move[] {
        return this.availableMoves;
    }

    override describe(playerNames?: DescribePlayerNames): string {
        const playerName = getDescribePlayerName(this.getTurn(), playerNames);
        const roll = this.roll.value();
        return `Waiting for ${playerName} to make a move with their roll of ${roll}`;
    }

    override equals(other: any): boolean {
        if (!other || this.constructor !== other.constructor)
            return false;
        if (!(other instanceof WaitingForMoveGameState))
            throw new Error("Same constructor, but instanceof returns false");

        return PlayableGameState._baselineEquals(this, other)
            && this.roll.equals(other.roll)
            && Move.setEquals(this.availableMoves, other.availableMoves);
    }
}
