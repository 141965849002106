import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { Roll } from "@/ts/royalur/model/dice/Roll";
import { ActionGameState } from "@/ts/royalur/rules/state/ActionGameState";
import { Move } from "@/ts/royalur/model/Move";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import {
    DescribePlayerNames,
    getDescribePlayerName,
} from "@/ts/royalur/rules/state/GameState";


/**
 * A game state that represents a move of a piece on the board.
 */
export class MovedGameState extends ActionGameState {
    private readonly roll: Roll;
    private readonly move: Move;

    constructor(
        board: Board,
        lightPlayer: PlayerState,
        darkPlayer: PlayerState,
        turn: PlayerType,
        roll: Roll,
        move: Move,
    ) {
        super(board, lightPlayer, darkPlayer, turn);
        this.roll = roll;
        this.move = move;
    }

    getRoll(): Roll {
        return this.roll;
    }

    getMove(): Move {
        return this.move;
    }

    override describe(playerNames?: DescribePlayerNames): string {
        const playerName = getDescribePlayerName(this.getTurn(), playerNames);
        const prefix = `${playerName} `;

        const introducing = this.move.isIntroduction();
        const scoring = this.move.isScore();

        if (introducing && scoring) {
            return prefix + "scored a newly introduced piece";
        } else if (scoring) {
            return prefix + "scored their " + this.move.getSource().toString() + " piece";
        } else if (introducing) {
            return prefix + "introduced a piece to " + this.move.getDest().toString();
        } else {
            return prefix + "moved their "
                + this.move.getSource().toString() + " piece to "
                + (this.move.isCapture() ? "capture " : "")
                + this.move.getDest().toString();
        }
    }

    override equals(other: any): boolean {
        if (!other || this.constructor !== other.constructor)
            return false;
        if (!(other instanceof MovedGameState))
            throw new Error("Same constructor, but instanceof returns false");

        return ActionGameState._baselineEquals(this, other)
            && this.roll.equals(other.roll)
            && this.move.equals(other.move);
    }
}
