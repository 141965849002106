import { Game } from "@/ts/royalur/Game";
import { Move } from "@/ts/royalur/model/Move";


export abstract class Agent {
    abstract decideMove(
        game: Game,
        availableMoves: Move[],
    ): Move;

    playTurn(game: Game): Promise<Game> {
        if (game.isFinished())
            throw new Error("The game has already been completed");
        if (!game.isPlayable())
            throw new Error("The game is not in a playable state");

        const newGame = game.copy();

        // Just roll the dice, there's not usually any decisions to be made here.
        if (newGame.isWaitingForRoll()) {
            newGame.rollDice();
            return Promise.resolve(newGame);
        }

        // Decide the move to be made.
        if (newGame.isWaitingForMove()) {
            const availableMoves = newGame.findAvailableMoves();
            const chosenMove = this.decideMove(newGame, availableMoves);
            newGame.move(chosenMove);
            return Promise.resolve(newGame);
        }
        throw new Error("The game is in an unexpected state");
    }
}
