import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Tile } from "@/ts/royalur/model/Tile";


/**
 * Represents a pair of paths for the light and dark players to
 * move their pieces along in a game of the Royal Game of Ur.
 */
export class PathPair {

    private readonly id: string;

    private readonly lightWithStartEnd: Tile[];
    private readonly darkWithStartEnd: Tile[];

    private readonly light: Tile[];
    private readonly dark: Tile[];

    private readonly lightStart: Tile;
    private readonly lightEnd: Tile;
    private readonly darkStart: Tile;
    private readonly darkEnd: Tile;

    /**
     * Instantiates a pair of paths.
     */
    constructor(id: string, light: Tile[], dark: Tile[]) {
        this.id = id;
        this.lightWithStartEnd = [...light];
        this.darkWithStartEnd = [...dark];
        this.light = this.lightWithStartEnd.slice(1, light.length - 1);
        this.dark = this.darkWithStartEnd.slice(1, dark.length - 1);
        this.lightStart = light[0];
        this.lightEnd = light[light.length - 1];
        this.darkStart = dark[0];
        this.darkEnd = dark[dark.length - 1];
    }

    getID(): string {
        return this.id;
    }

    /**
     * Gets the path that the light player's pieces must take, excluding
     * the start and end tiles that exist off the board.
     */
    getLight(): Tile[] {
        return this.light;
    }

    /**
     * Gets the path that the dark player's pieces must take, excluding
     * the start and end tiles that exist off the board.
     */
    getDark(): Tile[] {
        return this.dark;
    }

    /**
     * Retrieves the path of `player`, excluding the start and end
     * tiles that exist off the board.
     */
    get(player: PlayerType): Tile[] {
        switch (player) {
            case PlayerType.LIGHT: return this.getLight();
            case PlayerType.DARK: return this.getDark();
            default:
                throw new Error("Unknown player: " + player.getName());
        }
    }

    /**
     * Gets the path that the light player's pieces must take, including
     * the start and end tiles that exist off the board.
     */
    getLightWithStartEnd(): Tile[] {
        return this.lightWithStartEnd;
    }

    /**
     * Gets the path that the dark player's pieces must take, including
     * the start and end tiles that exist off the board.
     */
    getDarkWithStartEnd(): Tile[] {
        return this.darkWithStartEnd;
    }

    /**
     * Retrieves the path of `player`, including the start and end
     * tiles that exist off the board.
     * @param player The player to get the path for.
     * @return The path for the given player.
     */
    getWithStartEnd(player: PlayerType): Tile[] {
        switch (player) {
            case PlayerType.LIGHT: return this.getLightWithStartEnd();
            case PlayerType.DARK: return this.getDarkWithStartEnd();
            default:
                throw new Error("Unknown player: " + player.getName());
        }
    }

    /**
     * Gets the start tile for the light player that exists off the board.
     */
    getLightStart(): Tile {
        return this.lightStart;
    }

    /**
     * Gets the start tile for the dark player that exists off the board.
     */
    getDarkStart(): Tile {
        return this.darkStart;
    }

    /**
     * Retrieves the start tile of {@code player} that exists off the board.
     */
    getStart(player: PlayerType): Tile {
        switch (player) {
            case PlayerType.LIGHT: return this.getLightStart();
            case PlayerType.DARK: return this.getDarkStart();
            default:
                throw new Error("Unknown player: " + player.getName());
        }
    }

    /**
     * Gets the end tile for the light player that exists off the board.
     */
    getLightEnd(): Tile {
        return this.lightEnd;
    }

    /**
     * Gets the end tile for the dark player that exists off the board.
     */
    getDarkEnd(): Tile {
        return this.darkEnd;
    }

    /**
     * Gets the end tile of {@code player} that exists off the board.
     */
    getEnd(player: PlayerType): Tile {
        switch (player) {
            case PlayerType.LIGHT: return this.getLightEnd();
            case PlayerType.DARK: return this.getDarkEnd();
            default:
                throw new Error("Unknown player: " + player.getName());
        }
    }

    /**
     * Determines whether the paths that the light player's pieces must take,
     * and the paths that the dark player's pieces must take, are equivalent
     * between this path pair and `other`. The start and end tiles that
     * exist off the board may still differ between the paths.
     */
    isEquivalent(other: PathPair): boolean {
        return Tile.listEquals(this.getLight(), other.getLight())
            && Tile.listEquals(this.getDark(), other.getDark());
    }

    equals(other: PathPair): boolean {
        return Tile.listEquals(
            this.getLightWithStartEnd(), other.getLightWithStartEnd(),
        ) && Tile.listEquals(
            this.getDarkWithStartEnd(), other.getDarkWithStartEnd(),
        );
    }
}
