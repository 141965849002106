import { Dice } from "@/ts/royalur/model/dice/Dice";
import { Roll } from "@/ts/royalur/model/dice/Roll";
import { randInt } from "@/ts/util/random";
import { BasicRoll } from "@/ts/royalur/model/dice/BasicRoll";
import { bitCount } from "@/ts/util/numbers";


/**
 * Rolls a number of binary dice and counts the result.
 */
export class BinaryDice extends Dice {
    /**
     * The number of binary dice to roll.
     */
    private readonly numDie: number;

    /**
     * The probability of rolling each value with these dice.
     */
    private readonly rollProbabilities: number[];

    /**
     * Instantiates this binary dice with `random` as the source
     * of randomness to generate rolls.
     */
    constructor(id: string, numDie: number) {
        super(id);
        if (numDie <= 0)
            throw new Error("numDie must be at least 1");
        if (numDie >= 31)
            throw new Error("numDie must be less than 32");

        this.numDie = numDie;
        this.rollProbabilities = [];

        // Binomial Distribution
        const baseProb = Math.pow(0.5, numDie);
        let nChooseK = 1;
        for (let roll = 0; roll <= numDie; ++roll) {
            this.rollProbabilities.push(baseProb * nChooseK);
            nChooseK = nChooseK * (numDie - roll) / (roll + 1);
        }
    }

    override getMaxRollValue(): number {
        return this.numDie;
    }

    override getRollProbabilities(): number[] {
        return this.rollProbabilities;
    }

    override rollValue(): number {
        // Each generated bit represents a roll of a D2 dice.
        const bits = randInt(1 << this.numDie);
        return bitCount(bits);
    }

    override generateRoll(value: number): Roll {
        if (value < 0 || value > this.getMaxRollValue())
            throw new Error("This dice cannot roll " + value);

        return BasicRoll.of(value);
    }
}
