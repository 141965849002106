

/**
 * A list of moves that may be reused without allocating memory.
 */
export class FastMoveList {
    /**
     * Path indices of tiles to move. -1 represents introducing a piece.
     * Only {@link #moveCount} moves are populated in this array.
     */
    public moves: number[];

    public moveCount: number;

    constructor(initialCapacity: number = 8) {
        this.moves = new Array(initialCapacity).fill(0);
        this.moveCount = 0;
    }

    public clear(): void {
        this.moveCount = 0;
    }

    public add(pathIndex: number): void {
        if (this.moveCount === this.moves.length) {
            this.moves.push(pathIndex);
        } else {
            this.moves[this.moveCount] = pathIndex;
        }
        this.moveCount += 1;
    }
}
