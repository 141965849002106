import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Piece } from "@/ts/royalur/model/Piece";


/**
 * An interface that provides instances of pieces. This may be used
 * to store custom information with each piece, for situations such
 * as adding stacking or unique piece behavior.
 */
export abstract class PieceProvider {
    /**
     * Generate a piece on the board.
     */
    abstract create(owner: PlayerType, pathIndex: number): Piece;

    /**
     * Generates a new piece to be introduced to the board.
     */
    abstract createIntroduced(owner: PlayerType, newPathIndex: number): Piece;

    /**
     * Generates a piece that has been moved from another tile on the board.
     */
    abstract createMoved(fromPiece: Piece, newPathIndex: number): Piece;
}
