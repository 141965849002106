import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { GameState } from "@/ts/royalur/rules/state/GameState";
import { Board } from "@/ts/royalur/model/Board";


/**
 * A game state that records a control action that was made.
 */
export abstract class ControlGameState extends GameState {
    private readonly player: PlayerType | null;

    protected constructor(
        board: Board,
        lightPlayer: PlayerState,
        darkPlayer: PlayerState,
        player: PlayerType | null,
    ) {
        super(board, lightPlayer, darkPlayer);
        this.player = player;
    }

    override isPlayable(): boolean {
        return false;
    }

    override isFinished(): boolean {
        return false;
    }

    override getSubject(): PlayerType | null {
        return this.player;
    }

    hasPlayer(): boolean {
        return this.player !== null;
    }

    getPlayer(): PlayerType {
        if (this.player === null)
            throw new Error("This control state does not have an associated player");
        return this.player;
    }

    /**
     * Returns whether the baseline ControlGameState values are equal
     * between the two provided game states.
     */
    static _baselineEquals(one: ControlGameState, two: ControlGameState): boolean {
        return GameState._baselineEquals(one, two) && one.player === two.player;
    }
}
