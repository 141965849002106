import { RuleSetProvider } from "@/ts/royalur/rules/RuleSetProvider";
import { GameSettings } from "@/ts/royalur/model/GameSettings";
import { SimplePieceProvider } from "@/ts/royalur/rules/simple/SimplePieceProvider";
import { SimplePlayerStateProvider } from "@/ts/royalur/rules/simple/SimplePlayerStateProvider";
import { SimpleRuleSet } from "@/ts/royalur/rules/simple/SimpleRuleSet";
import { PieceProvider } from "@/ts/royalur/rules/PieceProvider";
import { PlayerStateProvider } from "@/ts/royalur/rules/PlayerStateProvider";

/**
 * A provider that creates simple rule sets.
 */
export class SimpleRuleSetProvider extends RuleSetProvider {

    createPieceProvider(
        _settings: GameSettings,
    ): PieceProvider {
        return new SimplePieceProvider();
    }

    createPlayerStateProvider(
        settings: GameSettings,
    ): PlayerStateProvider {
        return new SimplePlayerStateProvider(
            settings.getStartingPieceCount(),
        );
    }

    create(
        settings: GameSettings,
    ): SimpleRuleSet {
        return new SimpleRuleSet(
            settings,
            this.createPieceProvider(settings),
            this.createPlayerStateProvider(settings),
        );
    }
}
