import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { DescribePlayerNames, GameState, getDescribePlayerName } from "@/ts/royalur/rules/state/GameState";


/**
 * A game state where a player has won the game.
 */
export class EndGameState extends GameState {
    private readonly winner: PlayerType | null;

    constructor(
        board: Board,
        lightPlayer: PlayerState,
        darkPlayer: PlayerState,
        winner: PlayerType | null,
    ) {
        super(board, lightPlayer, darkPlayer);
        this.winner = winner;
    }

    override isPlayable(): boolean {
        return false;
    }

    override isFinished(): boolean {
        return true;
    }

    override getSubject(): PlayerType | null {
        return this.winner;
    }

    hasWinner(): boolean {
        return this.winner !== null;
    }

    /**
     * Retrieves the player that won the game.
     */
    getWinner(): PlayerType {
        if (this.winner === null)
            throw new Error("The game did not end with a winner");
        return this.winner;
    }

    hasLoser(): boolean {
        return this.winner !== null;
    }

    /**
     * Retrieves the player that lost the game.
     */
    getLoser(): PlayerType {
        if (this.winner === null)
            throw new Error("The game did not end with a loser");
        return this.winner.getOtherPlayer();
    }

    /**
     * Retrieves the state of the player that won the game.
     */
    getWinningPlayer(): PlayerState {
        return this.getPlayerState(this.getWinner());
    }

    /**
     * Retrieves the state of the player that lost the game.
     */
    getLosingPlayer(): PlayerState {
        return this.getPlayerState(this.getLoser());
    }

    override describe(playerNames: DescribePlayerNames): string {
        if (this.winner === null)
            throw new Error("The game ended without a winner");

        const playerName = getDescribePlayerName(this.winner, playerNames);
        return `${playerName} has won!`;
    }

    override equals(other: any): boolean {
        if (!other || this.constructor !== other.constructor)
            return false;
        if (!(other instanceof EndGameState))
            throw new Error("Same constructor, but instanceof returns false");

        return GameState._baselineEquals(this, other)
            && this.winner === other.winner;
    }
}
