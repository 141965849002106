import { PlayableGameState } from "@/ts/royalur/rules/state/PlayableGameState";
import { DescribePlayerNames, getDescribePlayerName } from "@/ts/royalur/rules/state/GameState";


/**
 * A game state where the game is waiting for a player to roll the dice.
 */
export class WaitingForRollGameState extends PlayableGameState {
    override describe(playerNames?: DescribePlayerNames): string {
        const playerName = getDescribePlayerName(this.getTurn(), playerNames);
        return `Waiting for ${playerName} to roll the dice`;
    }

    override equals(other: any): boolean {
        if (!other || this.constructor !== other.constructor)
            return false;
        if (!(other instanceof WaitingForRollGameState))
            throw new Error("Same constructor, but instanceof returns false");

        return PlayableGameState._baselineEquals(this, other);
    }
}
