import { areMapsEqual } from "@/ts/royalur/util/collections";
import { TimeControl } from "@/ts/royalur/model/TimeControl";


/**
 * Stores the metadata of games, such as the date the game was played,
 * the players of the game, and the game standard.
 */
export class GameMetadata {
    /**
     * The key for storing when a game started.
     */
    public static readonly START_TIME_KEY = "StartTime";

    /**
     * The key for storing when a game was finished.
     */
    public static readonly END_TIME_KEY = "EndTime";

    /**
     * The key for storing the time control of a game.
     */
    public static readonly TIME_CONTROL_KEY = "TimeControl";

    /**
     * Arbitrary metadata about a game.
     */
    private readonly metadata: Map<string, string>;

    constructor(metadata?: Map<string, string>) {
        this.metadata = metadata ? new Map(metadata) : new Map();
    }

    /**
     * Creates a copy of this metadata.
     */
    copy(): GameMetadata {
        return new GameMetadata(this.metadata);
    }

    /**
     * Retrieves a copy of all the metadata stored.
     */
    getAll(): Map<string, string> {
        return new Map(this.metadata);
    }

    /**
     * Get the metadata value associated with `key`, or `null`
     * if there is no value associated with the key.
     */
    get(key: string): string | undefined {
        return this.metadata.get(key);
    }

    /**
     * Removes any metadata value associated with `key`.
     */
    remove(key: string): void {
        this.metadata.delete(key);
    }

    /**
     * Add a new metadata value, `value`, associated with `key`.
     */
    put(key: string, value: string): void {
        this.metadata.set(key, value);
    }

    /**
     * Sets the date and time when this game began.
     */
    setStartTime(datetime: Date): void {
        this.put(GameMetadata.START_TIME_KEY, datetime.toISOString());
    }

    /**
     * Gets the date and time when this game began, or `null`
     * if no end time is included in this game's metadata.
     */
    getStartTime(): Date | null {
        const formatted = this.get(GameMetadata.START_TIME_KEY);
        if (!formatted)
            return null;

        return new Date(formatted);
    }

    /**
     * Sets the date and time when this game was finished.
     */
    setEndTime(datetime: Date): void {
        this.put(GameMetadata.END_TIME_KEY, datetime.toISOString());
    }

    /**
     * Gets the date and time when this game was finished, or {@code null}
     * if no end time is included in this game's metadata.
     */
    getEndTime(): Date | null {
        const formatted = this.get(GameMetadata.END_TIME_KEY);
        if (!formatted)
            return null;

        return new Date(formatted);
    }

    /**
     * Sets the time control used for this game.
     */
    setTimeControl(timeControl: TimeControl): void {
        this.put(GameMetadata.TIME_CONTROL_KEY, timeControl.toString());
    }

    /**
     * Gets the time control used for this game, or {@code null} if no
     * time control was included in this game's metadata.
     */
    getTimeControl(): TimeControl | null {
        const text = this.get(GameMetadata.TIME_CONTROL_KEY);
        if (text == null)
            return null;

        return TimeControl.fromString(text);
    }

    equals(other: GameMetadata): boolean {
        return areMapsEqual(this.metadata, other.metadata);
    }

    toString(): string {
        return JSON.stringify(this.metadata);
    }

    /**
     * Creates and initialises metadata for a new game.
     */
    static createForNewGame(): GameMetadata {
        const metadata = new GameMetadata();
        metadata.setStartTime(new Date());
        return metadata;
    }
}
