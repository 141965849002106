import { FastPlayer } from "@/ts/royalur/rules/simple/fast/FastPlayer";
import { FastBoard } from "@/ts/royalur/rules/simple/fast/FastBoard";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { Tile } from "@/ts/royalur/model/Tile";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Game } from "@/ts/royalur/Game";
import { FastMoveList } from "@/ts/royalur/rules/simple/fast/FastMoveList";


/**
 * A simple game that is optimised for speed.
 * This speed comes at the cost of error checking,
 * convenience, and tracking of game history.
 */
export class FastGame {
    public readonly areRosettesSafe: boolean;
    public readonly rosettesGrantExtraRoll: boolean;
    public readonly capturesGrantExtraRoll: boolean;
    public readonly startingPieceCount: number;

    public readonly board: FastBoard;
    public readonly light: FastPlayer;
    public readonly dark: FastPlayer;

    public isLightTurn: boolean;
    public rollValue: number;
    public isFinished: boolean;

    constructor(rules: RuleSet, startingPieceCount: number) {
        this.areRosettesSafe = rules.areRosettesSafe();
        this.rosettesGrantExtraRoll = rules.doRosettesGrantExtraRolls();
        this.capturesGrantExtraRoll = rules.doCapturesGrantExtraRolls();
        this.startingPieceCount = startingPieceCount;
        this.board = new FastBoard(rules.getBoardShape());

        const lightPath = FastGame.tilesToIndices(this.board, rules.getPaths().getLight());
        const darkPath = FastGame.tilesToIndices(this.board, rules.getPaths().getDark());

        this.light = new FastPlayer(lightPath, true);
        this.dark = new FastPlayer(darkPath, false);

        this.isLightTurn = true;
        this.rollValue = -1;
        this.isFinished = false;
    }

    getPlayer(isLight: boolean): FastPlayer {
        return isLight ? this.light : this.dark;
    }

    getTurnPlayer(): FastPlayer {
        return this.getPlayer(this.isLightTurn);
    }

    copyFrom(other: FastGame): void {
        this.board.copyFrom(other.board);
        this.light.copyFrom(other.light);
        this.dark.copyFrom(other.dark);
        this.isLightTurn = other.isLightTurn;
        this.rollValue = other.rollValue;
        this.isFinished = other.isFinished;
    }

    copyFromGame(game: Game): void {
        this.board.copyFromBoard(game.getBoard());
        this.light.copyFromState(game.getLightPlayer());
        this.dark.copyFromState(game.getDarkPlayer());
        this.isLightTurn = game.getTurn() === PlayerType.LIGHT;
        this.rollValue = game.isWaitingForMove() ? game.getRoll().value() : -1;
        this.isFinished = game.isFinished();
    }

    /**
     * Checks whether a roll of the dice is expected.
     * This may return true when the game has already
     * finished. Therefore, if the game may have finished,
     * you should check that first.
     */
    isWaitingForRoll(): boolean {
        return this.rollValue < 0;
    }

    /**
     * Checks whether moving a piece is expected.
     * This may return true when the game has already
     * finished. Therefore, if the game may have finished,
     * you should check that first.
     */
    isWaitingForMove(): boolean {
        return this.rollValue >= 0;
    }

    findAvailableMoves(moveList: FastMoveList): void {
        moveList.clear();

        const rollValue = this.rollValue;
        if (rollValue < 0)
            throw new Error("No roll has been made");

        const turnPlayer = this.getTurnPlayer();
        const turnPlayerSign = turnPlayer.sign;
        const path = turnPlayer.path;
        const boardPieces = this.board.pieces;

        // Check if a piece can be taken off the board.
        if (rollValue <= path.length) {
            const scorePathIndex = path.length - rollValue;
            const scoreTileIndex = path[scorePathIndex];
            const scorePiece = boardPieces[scoreTileIndex];
            if (scorePiece === turnPlayerSign * (scorePathIndex + 1)) {
                moveList.add(scorePathIndex);
            }
        }

        // Check moving a piece on the board and introducing a piece.
        const areRosettesSafe = this.areRosettesSafe;
        const turnPlayerHasPieces = (turnPlayer.pieces > 0);
        for (let pathIndex = -1; pathIndex < path.length - rollValue; ++pathIndex) {
            if (pathIndex >= 0) {
                // Move a piece on the board.
                const tileIndex = path[pathIndex];
                const piece = boardPieces[tileIndex];
                if (piece !== turnPlayerSign * (pathIndex + 1))
                    continue;
            } else if (!turnPlayerHasPieces) {
                // Can't introduce a piece to the board.
                continue;
            }

            // Check if the destination is free.
            const destPathIndex = pathIndex + rollValue;
            const destTileIndex = path[destPathIndex];
            const destPiece = boardPieces[destTileIndex];
            if (destPiece !== 0) {
                // Can't capture your own pieces.
                if (destPiece * turnPlayerSign > 0)
                    continue;

                // Can't capture pieces on rosettes if they are safe.
                if (areRosettesSafe && this.board.isTileRosette(destTileIndex))
                    continue;
            }

            // Add the move to the list.
            moveList.add(pathIndex);
        }
    }

    applyRoll(rollValue: number, moveList: FastMoveList): void {
        if (this.rollValue >= 0)
            throw new Error("A roll has already been made");

        // Swap turn when rolling a zero
        if (rollValue === 0) {
            moveList.clear();
            this.isLightTurn = !this.isLightTurn;
            return;
        }

        // Determine if the player has any available moves
        this.rollValue = rollValue;
        this.findAvailableMoves(moveList);
        if (moveList.moveCount === 0) {
            this.isLightTurn = !this.isLightTurn;
            this.rollValue = -1;
        }
    }

    shouldGrantRoll(destTileIndex: number, capturedPiece: number): boolean {
        if (this.rosettesGrantExtraRoll && destTileIndex >= 0 && this.board.isTileRosette(destTileIndex))
            return true;

        return this.capturesGrantExtraRoll && capturedPiece !== 0;
    }

    applyMove(pathIndex: number): void {
        const rollValue = this.rollValue;
        if (rollValue < 0)
            throw new Error("No roll has been made");

        // We are using the roll now, so clear it.
        this.rollValue = -1;

        const turnPlayer = this.getTurnPlayer();
        const turnPlayerSign = turnPlayer.sign;
        const path = turnPlayer.path;
        const boardPieces = this.board.pieces;

        if (pathIndex >= 0) {
            // Moving a piece on the board.
            const sourceTileIndex = path[pathIndex];
            boardPieces[sourceTileIndex] = 0;
        } else {
            // Introducing a piece to the board.
            turnPlayer.pieces -= 1;
        }

        const destPathIndex = pathIndex + rollValue;
        let destTileIndex = -1;
        let capturedPiece = 0;

        if (destPathIndex < path.length) {
            // Moving a piece on the board.
            destTileIndex = path[destPathIndex];
            capturedPiece = boardPieces[destTileIndex];
            if (capturedPiece !== 0) {
                this.getPlayer(capturedPiece > 0).pieces += 1;
            }
            boardPieces[destTileIndex] = turnPlayerSign * (destPathIndex + 1);
        } else {
            // Scoring a piece.
            turnPlayer.score += 1;
            if (turnPlayer.score >= this.startingPieceCount) {
                this.isFinished = true;
                return;
            }
        }

        // Determine whose turn it should be.
        if (!this.shouldGrantRoll(destTileIndex, capturedPiece)) {
            this.isLightTurn = !this.isLightTurn;
        }
    }

    private static tilesToIndices(board: FastBoard, tiles: Tile[]): number[] {
        const indices: number[] = [];
        for (const tile of tiles) {
            indices.push(board.calcTileIndex(
                tile.getXIndex(), tile.getYIndex(),
            ));
        }
        return indices;
    }
}
