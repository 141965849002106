import { Piece } from "@/ts/royalur/model/Piece";
import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { Roll } from "@/ts/royalur/model/dice/Roll";
import { GameState } from "@/ts/royalur/rules/state/GameState";
import { GameMetadata } from "@/ts/royalur/GameMetadata";
import { Dice } from "@/ts/royalur/model/dice/Dice";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { Move } from "@/ts/royalur/model/Move";
import { Tile } from "@/ts/royalur/model/Tile";
import { ActionGameState } from "@/ts/royalur/rules/state/ActionGameState";
import { EndGameState } from "@/ts/royalur/rules/state/EndGameState";
import { PlayableGameState } from "@/ts/royalur/rules/state/PlayableGameState";
import { WaitingForRollGameState } from "@/ts/royalur/rules/state/WaitingForRollGameState";
import { WaitingForMoveGameState } from "@/ts/royalur/rules/state/WaitingForMoveGameState";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { GameSettings } from "@/ts/royalur/model/GameSettings";
import { SimpleRuleSetProvider } from "@/ts/royalur/rules/simple/SimpleRuleSetProvider";
import { MovedGameState } from "@/ts/royalur/rules/state/MovedGameState";
import { ControlGameState } from "@/ts/royalur/rules/state/ControlGameState";
import { ResignedGameState } from "@/ts/royalur/rules/state/ResignedGameState";
import { AbandonedGameState } from "@/ts/royalur/rules/state/AbandonedGameState";
import { AbandonReason } from "@/ts/royalur/model/AbandonReason";


/**
 * A game of the Royal Game of Ur. Provides methods to allow the playing of games,
 * and methods to support the retrieval of history about the moves that were made.
 */
export class Game {
    private readonly rules: RuleSet;
    private readonly dice: Dice;
    private readonly metadata: GameMetadata;
    private readonly states: GameState[];

    constructor(
        rules: RuleSet,
        states?: GameState[],
        metadata?: GameMetadata,
    ) {
        if (states === undefined) {
            states = [rules.generateInitialGameState()];
        }
        if (metadata === undefined) {
            metadata = GameMetadata.createForNewGame();
        }
        if (states.length === 0)
            throw new Error("Games must have at least one state to play from");
        if (!(states[0] instanceof PlayableGameState))
            throw new Error("Games must start with a playable game state");

        this.rules = rules;
        this.dice = this.rules.getDiceFactory().createDice();
        this.metadata = metadata;
        this.states = [];

        this.addStates(states);
    }

    /**
     * Adds all states from `states` to this game.
     */
    addStates(states: GameState[]) {
        if (states.length === 0)
            throw new Error("There were no states to add");

        for (const state of states) {
            if (state instanceof ControlGameState && this.getLastControlStateOrNull() !== null) {
                throw new Error(
                    "Only a single control game state per game is currently supported"
                );
            }
            this.addState(state);
        }
    }

    /**
     * Adds the state `state` to this game.
     */
    addState(state: GameState) {
        this.states.push(state);
    }

    /**
     * Generates a copy of this game.
     */
    copy(): Game {
        if (this.constructor !== Game) {
            throw new Error(`${this.constructor} does not support copy`);
        }
        return new Game(this.rules, this.states, this.metadata.copy());
    }

    /**
     * Gets the set of rules that are being used for this game.
     */
    getRules(): RuleSet {
        return this.rules;
    }

    /**
     * Gets the dice to are used to make dice rolls.
     */
    getDice(): Dice {
        return this.dice;
    }

    /**
     * Retrieves the metadata of this game.
     */
    getMetadata(): GameMetadata {
        return this.metadata;
    }

    /**
     * Retrieves the states that have occurred so far in the game.
     * The last state in the list is the current state of the game.
     */
    getStates(): GameState[] {
        return [...this.states];
    }

    /**
     * Retrieves the states that represent the moves that have been
     * made so far in the game.
     */
    getMoveStates(): MovedGameState[] {
        const actionStates: MovedGameState[] = [];
        for (const state of this.states) {
            if (state instanceof MovedGameState) {
                actionStates.push(state);
            }
        }
        return actionStates;
    }

    /**
     * Retrieves the states that represent the actions that have been
     * made so far in the game. The last state in the list represents
     * the last action that was taken in this game.
     */
    getActionStates(): ActionGameState[] {
        const actionStates: ActionGameState[] = [];
        for (const state of this.states) {
            if (state instanceof ActionGameState) {
                actionStates.push(state);
            }
        }
        return actionStates;
    }

    /**
     * Retrieves only the states that are required to reproduce
     * exactly what happened in a typical game.
     */
    getLandmarkStates(): GameState[] {
        return this.rules.selectLandmarkStates(this.states);
    }

    /**
     * Retrieve the state that the game is currently in.
     */
    getState(): GameState {
        if (this.states.length === 0)
            throw new Error("Game is unexpectedly missing all states");

        return this.states[this.states.length - 1];
    }

    /**
     * Determines whether the game is currently in a playable state.
     */
    isPlayable(): boolean {
        return this.getState().isPlayable();
    }

    /**
     * Determines whether the game is currently in a state that is waiting
     * for a roll from a player.
     */
    isWaitingForRoll(): boolean {
        return this.getState() instanceof WaitingForRollGameState;
    }

    /**
     * Determines whether the game is currently in a state that is waiting
     * for a move from a player.
     */
    isWaitingForMove(): boolean {
        return this.getState() instanceof WaitingForMoveGameState;
    }

    /**
     * Determines whether the game is currently in a finished state.
     */
    isFinished(): boolean {
        return this.getState().isFinished();
    }

    /**
     * Retrieves the current state of this game as an instance
     * of `WaitingForRollGameState`. This will throw an error if the
     * game is not in a state that is waiting for a roll from a player.
     */
    getWaitingForRollState(): WaitingForRollGameState {
        const state = this.getState();
        if (!(state instanceof WaitingForRollGameState))
            throw new Error("This game is not waiting for a roll");

        return state;
    }

    /**
     * Retrieves the current state of this game as an instance
     * of `WaitingForMoveGameState`. This will throw an error if the
     * game is not in a state that is waiting for a move from a player.
     */
    getWaitingForMoveState(): WaitingForMoveGameState {
        const state = this.getState();
        if (!(state instanceof WaitingForMoveGameState))
            throw new Error("This game is not waiting for a move");

        return state;
    }

    /**
     * Checks whether the given player has made any moves in this game.
     * @param player The player to check.
     * @return Whether the given player has made any moves in this game.
     */
    hasPlayerMadeAnyMoves(player: PlayerType): boolean {
        for (const state of this.states) {
            if (state instanceof MovedGameState && state.getTurn() == player)
                return true;
        }
        return false;
    }

    /**
     * Retrieves the current state of this game as a `PlayableGameState`.
     * This will throw an error if the game is not in a playable state.
     */
    getPlayableState(): PlayableGameState {
        const state = this.getState();
        if (!state.isPlayable())
            throw new Error("This game is not in a playable game state");
        if (!(state instanceof PlayableGameState))
            throw new Error("The game is playable, but the state is not a PlayableGameState");

        return state;
    }

    /**
     * Gets the last control state in this game, or null if there
     * is no control state in this game.
     */
    getLastControlStateOrNull(): ControlGameState | null {
        for (let index = this.states.length - 1; index >= 0; --index) {
            const state = this.states[index];
            if (state instanceof ControlGameState)
                return state;
        }
        return null;
    }

    getResignedState(): ResignedGameState {
        const state = this.getLastControlStateOrNull();
        if (state instanceof ResignedGameState)
            return state;

        throw new Error("A player did not resign");
    }

    getAbandonedState(): AbandonedGameState {
        const state = this.getLastControlStateOrNull();
        if (state instanceof AbandonedGameState)
            return state;

        throw new Error("The game was not abandoned");
    }

    /**
     * Retrieves the current state of this game as an instance
     * of `EndGameState`. This will throw an error if the game
     * has not ended.
     */
    getEndState(): EndGameState {
        const state = this.getState();
        if (!state.isFinished())
            throw new Error("This game has not ended");
        if (!(state instanceof EndGameState))
            throw new Error("Game has finished, but the state is not a EndGameState");

        return state;
    }

    /**
     * Rolls the dice, and updates the state of the game accordingly.
     */
    rollDice(): Roll;

    /**
     * Rolls the dice with a known value of `value`, and updates
     * the state of the game accordingly.
     */
    rollDice(value: number): Roll;

    /**
     * Rolls the dice, with a known value of `roll`, and updates the
     * state of the game accordingly.
     */
    rollDice(roll: Roll): Roll;

    rollDice(value?: Roll | number): Roll {
        let roll: Roll;
        if (value === undefined) {
            roll = this.dice.roll();
        } else if (typeof value === "number") {
            roll = this.dice.generateRoll(value);
        } else {
            roll = value;
        }
        const state = this.getWaitingForRollState();
        this.addStates(this.rules.applyRoll(state, roll));
        return roll;
    }

    /**
     * Finds all available moves that can be made from the current state of the game.
     */
    findAvailableMoves(): Move[] {
        const state = this.getWaitingForMoveState();
        return this.rules.findAvailableMoves(
            state.getBoard(), state.getTurnPlayer(), state.getRoll()
        );
    }

    findMoveByPiece(piece: Piece): Move {
        for (const move of this.findAvailableMoves()) {
            if (move.hasSource() && move.getSourcePiece().equals(piece))
                return move;
        }
        throw new Error(`The piece cannot be moved, ${piece.toString()}`);
    }

    findMoveByTile(tile: Tile): Move {
        const paths = this.rules.getPaths();
        for (const move of this.findAvailableMoves()) {
            if (tile.equals(move.getSource(paths)))
                return move;
        }
        throw new Error(`There is no piece that can be moved on ${tile.toString()}`);
    }

    findMoveIntroducingPiece(): Move {
        for (const move of this.findAvailableMoves()) {
            if (move.isIntroduction())
                return move;
        }
        throw new Error("There is no available move that introduces a piece");
    }

    findMoveScoringPiece(): Move {
        for (const move of this.findAvailableMoves()) {
            if (move.isScore())
                return move;
        }
        throw new Error("There is no available move that scores a piece");
    }

    move(move: Move) {
        const state = this.getWaitingForMoveState();
        this.addStates(this.rules.applyMove(state, move));
    }

    movePiece(piece: Piece) {
        this.move(this.findMoveByPiece(piece));
    }

    movePieceByTile(tile: Tile) {
        this.move(this.findMoveByTile(tile));
    }

    resign(player: PlayerType) {
        if (this.isFinished())
            throw new Error("The game is already finished");

        this.addStates(this.rules.applyResign(this.getState(), player));
    }

    abandon(reason: AbandonReason, player: PlayerType) {
        if (this.isFinished())
            throw new Error("The game is already finished");

        this.addStates(this.rules.applyAbandon(this.getState(), reason, player));
    }

    wasResigned(): boolean {
        return this.getLastControlStateOrNull() instanceof ResignedGameState;
    }

    getResigningPlayer(): PlayerType {
        return this.getResignedState().getPlayer();
    }

    wasAbandoned(): boolean {
        return this.getLastControlStateOrNull() instanceof AbandonedGameState;
    }

    getAbandonReason(): AbandonReason {
        return this.getAbandonedState().getReason();
    }

    wasAbandonedByPlayer(): boolean {
        return this.getAbandonedState().hasPlayer();
    }

    getAbandoningPlayer(): PlayerType {
        return this.getAbandonedState().getPlayer();
    }

    /**
     * Retrieves the state of the board in the current state of the game.
     */
    getBoard(): Board {
        return this.getState().getBoard();
    }

    /**
     * Retrieves the current state of the light player.
     */
    getLightPlayer(): PlayerState {
        return this.getState().getLightPlayer();
    }

    /**
     * Retrieves the current state of the dark player.
     */
    getDarkPlayer(): PlayerState {
        return this.getState().getDarkPlayer();
    }

    /**
     * Retrieves the state of the given player.
     */
    getPlayer(player: PlayerType): PlayerState {
        return this.getState().getPlayerState(player);
    }

    /**
     * Gets the player who can make the next interaction with the game.
     */
    getTurn(): PlayerType {
        return this.getPlayableState().getTurn();
    }

    /**
     * Gets the player who can make the next interaction with the game,
     * or the winner of the game if it is finished.
     * @return The player who can make the next interaction with the game,
     *         or the winner of the game if it is finished.
     */
    getTurnOrWinner(): PlayerType {
        return this.isFinished() ? this.getWinner() : this.getTurn();
    }

    /**
     * Retrieves the state of the player whose turn it is.
     */
    getTurnPlayer(): PlayerState {
        return this.getPlayableState().getTurnPlayer();
    }

    /**
     * Retrieves the state of the player that is waiting as it is not their turn.
     */
    getWaitingPlayer(): PlayerState {
        return this.getPlayableState().getWaitingPlayer();
    }

    /**
     * Retrieves the player that won the game.
     */
    getWinner(): PlayerType {
        return this.getEndState().getWinner();
    }

    /**
     * Determines whether this game has a winner.
     */
    hasWinner(): boolean {
        if (!this.isFinished())
            return false;
        return this.getEndState().hasWinner();
    }

    /**
     * Retrieves the player that lost the game.
     */
    getLoser(): PlayerType {
        return this.getEndState().getLoser();
    }

    /**
     * Retrieves the state of the winning player.
     */
    getWinningPlayer(): PlayerState {
        return this.getEndState().getWinningPlayer();
    }

    /**
     * Retrieves the state of the losing player.
     */
    getLosingPlayer(): PlayerState {
        return this.getEndState().getLosingPlayer();
    }

    /**
     * Retrieves the roll that was made that can be used by the
     * current turn player to make a move.
     */
    getRoll(): Roll {
        return this.getWaitingForMoveState().getRoll();
    }

    /**
     * Creates a simple game with custom settings.
     */
    static create(settings: GameSettings): Game {
        const ruleProvider = new SimpleRuleSetProvider();
        const rules = ruleProvider.create(settings);
        return new Game(rules);
    }

    /**
     * Creates a simple game that follows the rules proposed by Irving Finkel.
     * This uses the simple standard, the simple board shape, Bell"s path, safe
     * rosette tiles, the simple dice, and seven starting pieces per player.
     */
    static createFinkel(): Game {
        return Game.create(GameSettings.FINKEL);
    }

    /**
     * Creates a simple game that follows the rules proposed by James Masters.
     * This uses the simple standard, the simple board shape, Bell"s path, unsafe
     * rosette tiles, the simple dice, and seven starting pieces per player.
     */
    static createMasters(): Game {
        return Game.create(GameSettings.MASTERS);
    }

    /**
     * Creates a game of Aseb. This uses the simple standard, the Aseb board shape,
     * the Aseb paths, the simple dice, and five starting pieces per player.
     */
    static createAseb(): Game {
        return Game.create(GameSettings.ASEB);
    }
}
