import { OngoingGameState } from "@/ts/royalur/rules/state/OngoingGameState";


/**
 * A game state where we are waiting for interactions from a player.
 */
export abstract class PlayableGameState extends OngoingGameState {
    override isPlayable(): boolean {
        return true;
    }

    /**
     * Returns whether the baseline ActionGameState values are equal
     * between the two provided game states.
     */
    static _baselineEquals(one: PlayableGameState, two: PlayableGameState): boolean {
        return OngoingGameState._baselineEquals(one, two);
    }
}
