

/**
 * Determines whether `map1` and `map2` contain the same keys
 * and associated values.
 * @param map1 The first map.
 * @param map2 The second map.
 */
export function areMapsEqual<K, V>(map1: Map<K, V>, map2: Map<K, V>) {
    if (map1.size !== map2.size)
        return false;

    for (const [key, value1] of map1.entries()) {
        const value2 = map2.get(key);
        if (value1 !== value2)
            return false;
    }
    return true;
}
