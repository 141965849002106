import { BoardShape } from "@/ts/royalur/model/shape/BoardShape";
import { PathPair } from "@/ts/royalur/model/path/PathPair";
import { DiceFactory } from "@/ts/royalur/model/dice/DiceFactory";
import { AsebBoardShape } from "@/ts/royalur/model/shape/AsebBoardShape";
import { AsebPathPair } from "@/ts/royalur/model/path/AsebPathPair";
import { DiceType } from "@/ts/royalur/model/dice/DiceType";
import { StandardBoardShape } from "@/ts/royalur/model/shape/StandardBoardShape";
import { MastersPathPair } from "@/ts/royalur/model/path/MastersPathPair";
import { BellPathPair } from "@/ts/royalur/model/path/BellPathPair";
import { BoardShapeFactory } from "@/ts/royalur/model/shape/BoardShapeFactory";
import { PathPairFactory } from "@/ts/royalur/model/path/PathPairFactory";


/**
 * Settings for running games of the Royal Game of Ur.
 * This is built for convenience, and cannot represent all
 * possible combinations of rules that can be used to play
 * the Royal Game of Ur. If a more exotic set of rules is
 * desired, then you will need to construct your games
 * manually.
 */
export class GameSettings {
    /**
     * The rules used in the YouTube video Tom Scott vs. Irving Finkel.
     */
    static readonly FINKEL: GameSettings = new GameSettings(
        new StandardBoardShape(),
        new BellPathPair(),
        DiceType.FOUR_BINARY,
        7,
        true,
        true,
        false,
    );

    /**
     * The settings proposed by James Masters.
     */
    static readonly MASTERS: GameSettings = new GameSettings(
        new StandardBoardShape(),
        new MastersPathPair(),
        DiceType.FOUR_BINARY,
        7,
        false,
        true,
        false,
    );

    /**
     * The settings used for Aseb.
     */
    static readonly ASEB: GameSettings = new GameSettings(
        new AsebBoardShape(),
        new AsebPathPair(),
        DiceType.FOUR_BINARY,
        5,
        true,
        true,
        false,
    );

    private readonly boardShape: BoardShape;
    private readonly paths: PathPair;
    private readonly diceFactory: DiceFactory;
    private readonly startingPieceCount: number;
    private readonly safeRosettes: boolean;
    private readonly rosettesGrantExtraRolls: boolean;
    private readonly capturesGrantExtraRolls: boolean;

    constructor(
        boardShape: BoardShape,
        paths: PathPair,
        diceFactory: DiceFactory,
        startingPieceCount: number,
        safeRosettes: boolean,
        rosettesGrantExtraRolls: boolean,
        capturesGrantExtraRolls: boolean,
    ) {
        if (startingPieceCount < 1) {
            throw new Error(
                `startingPieceCount must be at least 1, not: ${startingPieceCount}`,
            );
        }

        this.boardShape = boardShape;
        this.paths = paths;
        this.diceFactory = diceFactory;
        this.startingPieceCount = startingPieceCount;
        this.safeRosettes = safeRosettes;
        this.rosettesGrantExtraRolls = rosettesGrantExtraRolls;
        this.capturesGrantExtraRolls = capturesGrantExtraRolls;
    }

    /**
     * Gets the shape of the game board.
     */
    getBoardShape(): BoardShape {
        return this.boardShape;
    }

    /**
     * Gets the paths that each player must take around the board.
     */
    getPaths(): PathPair {
        return this.paths;
    }

    /**
     * Gets the generator of the dice that should be used to generate dice rolls.
     */
    getDice(): DiceFactory {
        return this.diceFactory;
    }

    /**
     * Gets the number of pieces that each player starts with.
     */
    getStartingPieceCount(): number {
        return this.startingPieceCount;
    }

    /**
     * Gets whether pieces on rosette tiles are safe from capture.
     */
    areRosettesSafe(): boolean {
        return this.safeRosettes;
    }

    /**
     * Gets whether landing on a rosette tile grants the player an
     * additional roll of the dice.
     */
    doRosettesGrantExtraRolls(): boolean {
        return this.rosettesGrantExtraRolls;
    }

    /**
     * Gets whether capturing a piece grants the player an additional
     * roll of the dice.
     */
    doCapturesGrantExtraRolls(): boolean {
        return this.capturesGrantExtraRolls;
    }

    /**
     * Generates new game settings with `boardShape`.
     */
    withBoardShape(boardShape: BoardShape): GameSettings;

    /**
     * Generates new game settings with a board shape from `boardShapeFactory`.
     */
    withBoardShape(boardShapeFactory: BoardShapeFactory): GameSettings;

    withBoardShape(boardShape: BoardShape | BoardShapeFactory): GameSettings {
        if (!(boardShape instanceof BoardShape)) {
            boardShape = boardShape.createBoardShape();
        }
        return new GameSettings(
            boardShape, this.paths, this.diceFactory,
            this.startingPieceCount, this.safeRosettes,
            this.rosettesGrantExtraRolls, this.capturesGrantExtraRolls,
        );
    }

    /**
     * Generates new game settings with `paths`.
     */
    withPaths(paths: PathPair): GameSettings;

    /**
     * Generates new game settings with paths generated by `pathsFactory`.
     */
    withPaths(pathsFactory: PathPairFactory): GameSettings;

    withPaths(paths: PathPair | PathPairFactory): GameSettings {
        if (!(paths instanceof PathPair)) {
            paths = paths.createPathPair();
        }
        return new GameSettings(
            this.boardShape, paths, this.diceFactory,
            this.startingPieceCount, this.safeRosettes,
            this.rosettesGrantExtraRolls, this.capturesGrantExtraRolls,
        );
    }

    /**
     * Generates new game settings with `diceFactory`.
     */
    withDice(diceFactory: DiceFactory): GameSettings {
        return new GameSettings(
            this.boardShape, this.paths, diceFactory,
            this.startingPieceCount, this.safeRosettes,
            this.rosettesGrantExtraRolls, this.capturesGrantExtraRolls,
        );
    }

    /**
     * Generates new game settings with `startingPieceCount`.
     */
    withStartingPieceCount(startingPieceCount: number): GameSettings {
        return new GameSettings(
            this.boardShape, this.paths, this.diceFactory,
            startingPieceCount, this.safeRosettes,
            this.rosettesGrantExtraRolls, this.capturesGrantExtraRolls,
        );
    }

    /**
     * Generates new game settings with `safeRosettes`.
     */
    withSafeRosettes(safeRosettes: boolean): GameSettings {
        return new GameSettings(
            this.boardShape, this.paths, this.diceFactory,
            this.startingPieceCount, safeRosettes,
            this.rosettesGrantExtraRolls, this.capturesGrantExtraRolls,
        );
    }

    /**
     * Generates new game settings with `rosettesGrantExtraRolls`.
     */
    withRosettesGrantExtraRolls(rosettesGrantExtraRolls: boolean): GameSettings {
        return new GameSettings(
            this.boardShape, this.paths, this.diceFactory,
            this.startingPieceCount, this.safeRosettes,
            rosettesGrantExtraRolls, this.capturesGrantExtraRolls,
        );
    }

    /**
     * Generates new game settings with `capturesGrantExtraRolls`.
     */
    withCapturesGrantExtraRolls(capturesGrantExtraRolls: boolean): GameSettings {
        return new GameSettings(
            this.boardShape, this.paths, this.diceFactory,
            this.startingPieceCount, this.safeRosettes,
            this.rosettesGrantExtraRolls, capturesGrantExtraRolls,
        );
    }

    isEquivalent(other: GameSettings): boolean {
        return this.boardShape.isEquivalent(other.boardShape)
            && this.paths.isEquivalent(other.paths)
            && this.diceFactory.isEquivalent(other.diceFactory)
            && this.startingPieceCount === other.startingPieceCount
            && this.safeRosettes === other.safeRosettes
            && this.rosettesGrantExtraRolls === other.rosettesGrantExtraRolls
            && this.capturesGrantExtraRolls === other.capturesGrantExtraRolls;
    }
}
