import { Roll } from "@/ts/royalur/model/dice/Roll";


/**
 * A generator of dice rolls.
 */
export abstract class Dice {

    /**
     * The ID of this dice.
     */
    private readonly id: string;

    /**
     * Instantiates this dice with the given ID.
     */
    protected constructor(id: string) {
        this.id = id;
    }

    getID(): string {
        return this.id;
    }

    /**
     * Returns whether this dice holds any state that affects its dice rolls.
     * If this is overridden, then `copyFrom` should also be overridden.
     */
    hasState(): boolean {
        return false;
    }

    /**
     * Copies the state of `other` into this dice. If the dice does
     * not have state, this is a no-op. The state copied does not include
     * the seeding of the random number generator. If this is overridden,
     * then `hasState` should also be overridden.
     */
    copyFrom(_other: Dice): void {
        // Nothing to do.
    }

    /**
     * Gets the maximum value that could be rolled by this dice.
     */
    abstract getMaxRollValue(): number;

    /**
     * Gets the probability of rolling each value of the dice, where the
     * index into the returned array represents the value of the roll.
     */
    abstract getRollProbabilities(): number[];

    /**
     * Generates a random roll using this dice, and returns just the value.
     * If this dice has state, this should call `rollValue`.
     */
    abstract rollValue(): number;

    /**
     * Updates the state of this dice after having rolled `value`.
     */
    recordRoll(_value: number): void {
        // Nothing to do.
    }

    /**
     * Generates a roll with value `value` using this dice.
     */
    abstract generateRoll(value: number): Roll;

    /**
     * Generates a random roll using this dice.
     */
    roll(): Roll {
        return this.generateRoll(this.rollValue());
    }
}
