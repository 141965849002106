import { Tile } from "@/ts/royalur/model/Tile";
import { PathPair } from "@/ts/royalur/model/path/PathPair";


/**
 * A type of board shape available for the Royal Game of Ur.
 */
export class BoardShape {

    private readonly id: string;
    private readonly tiles: Tile[];
    private readonly rosettes: Tile[];
    private readonly width: number;
    private readonly height: number;

    constructor(id: string, tiles: Tile[], rosettes: Tile[]) {
        if (tiles.length <= 0)
            throw new Error("A board shape requires at least one tile");

        this.id = id;
        this.tiles = [...tiles];
        this.rosettes = [...rosettes];
        Tile.sortList(this.tiles);
        Tile.sortList(this.rosettes);

        const firstTile = tiles[0];
        let minX: number = firstTile.getX();
        let minY: number = firstTile.getY();
        let maxX: number = firstTile.getX();
        let maxY: number = firstTile.getY();
        for (let index = 1; index < tiles.length; ++index) {
            const tile = tiles[index];
            minX = Math.min(minX, tile.getX());
            minY = Math.min(minY, tile.getY());
            maxX = Math.max(maxX, tile.getX());
            maxY = Math.max(maxY, tile.getY());
        }
        if (minX !== 1 || minY !== 1) {
            // This is done in an attempt to standardise board shapes.
            throw new Error(
                "The board shape must be translated such that it has tiles "
                + "at an x-coordinate of 1, and at a y-coordinate of 1. "
                + "Minimum X = " + minX + ", Minimum Y = " + minY,
            );
        }
        this.width = maxX;
        this.height = maxY;

        for (let index = 1; index < rosettes.length; ++index) {
            const rosette = rosettes[index];
            if (!Tile.listContains(tiles, rosette)) {
                throw new Error(
                    "Rosette " + rosette.toString() + " does not exist on the board",
                );
            }
        }
    }

    getID(): string {
        return this.id;
    }

    /**
     * Gets the set of tiles that fall within the bounds of this board shape.
     */
    getTiles(): Tile[] {
        return this.tiles;
    }

    /**
     * Gets the set of tiles that represent rosette tiles in this board shape.
     */
    getRosettes(): Tile[] {
        return this.rosettes;
    }

    /**
     * Gets the width of the board shape.
     */
    getWidth(): number {
        return this.width;
    }

    /**
     * Gets the height of the board shape.
     */
    getHeight(): number {
        return this.height;
    }

    /**
     * Gets the number of tiles contained in this board shape.
     */
    getArea(): number {
        return this.tiles.length;
    }

    /**
     * Determines whether `tile` falls within this board shape.
     */
    contains(tile: Tile) {
        return Tile.listContains(this.tiles, tile);
    }

    /**
     * Determines whether the tile at the indices (`ix`, `iy`),
     * 0-based, falls within the bounds of this shape of board.
     */
    containsIndices(ix: number, iy: number) {
        if (ix < 0 || iy < 0 || ix >= this.width || iy >= this.height)
            return false;
        return this.contains(Tile.fromIndices(ix, iy));
    }

    /**
     * Determines whether all tiles in `tiles` are included
     * in this board shape.
     */
    containsAll(tiles: Tile[]): boolean {
        for (const tile of tiles) {
            if (!this.contains(tile))
                return false;
        }
        return true;
    }

    /**
     * Determines whether `tile` is a rosette tile in this board shape.
     */
    isRosette(tile: Tile): boolean {
        return Tile.listContains(this.rosettes, tile);
    }

    /**
     * Determines whether the tile at the indices (`ix`, `iy`),
     * 0-based, is a rosette tile in this board shape.
     */
    isRosetteIndices(ix: number, iy: number): boolean {
        if (ix < 0 || iy < 0 || ix >= this.width || iy >= this.height)
            return false;
        return this.isRosette(Tile.fromIndices(ix, iy));
    }

    /**
     * Determines whether `paths` can be used on this shape of board.
     */
    isCompatible(paths: PathPair): boolean {
        return this.containsAll(paths.getLight()) && this.containsAll(paths.getDark());
    }

    /**
     * Determines whether this board shape covers the same tiles,
     * and has the same rosettes, as `other`.
     */
    isEquivalent(other: BoardShape): boolean {
        return Tile.setEquals(this.tiles, other.tiles)
            && Tile.setEquals(this.rosettes, other.rosettes);
    }

    /**
     * Returns whether this shape and the shape provided cover
     * exactly the same layout of tiles with the same rosettes.
     */
    equals(other: BoardShape) {
        return this.id === other.id
            && Tile.setEquals(this.tiles, other.tiles)
            && Tile.setEquals(this.rosettes, other.rosettes);
    }
}
