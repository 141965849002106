import { PathPairFactory } from "@/ts/royalur/model/path/PathPairFactory";
import { PathPair } from "./PathPair";
import { BellPathPair } from "@/ts/royalur/model/path/BellPathPair";
import { AsebPathPair } from "@/ts/royalur/model/path/AsebPathPair";
import { MastersPathPair } from "@/ts/royalur/model/path/MastersPathPair";
import { MurrayPathPair } from "@/ts/royalur/model/path/MurrayPathPair";
import { SkiriukPathPair } from "@/ts/royalur/model/path/SkiriukPathPair";


/**
 * The type of path to use in a game.
 */
export class PathType implements PathPairFactory {

    /**
     * The path proposed by Bell for the Royal Game of Ur.
     */
    static readonly BELL: PathType = new PathType(
        "bell", "Bell", "Bell's Path (default)",
        () => new BellPathPair(),
    );

    /**
     * The simple path used for Aseb.
     */
    static readonly ASEB: PathType = new PathType(
        "aseb", "Aseb", "Path used for Aseb",
        () => new AsebPathPair(),
    );

    /**
     * The path proposed by Masters for the Royal Game of Ur.
     */
    static readonly MASTERS: PathType = new PathType(
        "masters", "Masters", "Masters' Path",
        () => new MastersPathPair(),
    );

    /**
     * The path proposed by Murray for the Royal Game of Ur.
     */
    static readonly MURRAY: PathType = new PathType(
        "murray", "Murray", "Murray's Path",
        () => new MurrayPathPair(),
    );

    /**
     * The path proposed by Skiriuk for the Royal Game of Ur.
     */
    static readonly SKIRIUK: PathType = new PathType(
        "skiriuk", "Skiriuk", "Skiriuk's Path",
        () => new SkiriukPathPair(),
    );

    static values(): PathType[] {
        return [
            PathType.BELL,
            PathType.ASEB,
            PathType.MASTERS,
            PathType.MURRAY,
            PathType.SKIRIUK,
        ];
    }

    static forStandardBoard(): PathType[] {
        return [
            PathType.BELL,
            PathType.MASTERS,
            PathType.MURRAY,
            PathType.SKIRIUK,
        ];
    }

    private static createParsingMap(): Record<string, PathPairFactory> {
        const map: Record<string, PathPairFactory> = {};
        map["Bell"] = PathType.BELL;
        map["Aseb"] = PathType.ASEB;
        map["Masters"] = PathType.MASTERS;
        map["Murray"] = PathType.MURRAY;
        map["Skiriuk"] = PathType.SKIRIUK;

        for (const value of PathType.values()) {
            map[value.id] = value;
        }
        return Object.freeze(map);
    }

    static readonly PARSING_MAP: Record<string, PathPairFactory> = PathType.createParsingMap();

    private readonly id: string;
    private readonly name: string;
    private readonly desc: string;
    private readonly createFn: () => PathPair;

    constructor(id: string, name: string, desc: string, createFn: () => PathPair) {
        this.id = id;
        this.name = name;
        this.desc = desc;
        this.createFn = createFn;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getDesc(): string {
        return this.desc;
    }

    createPathPair(): PathPair {
        return this.createFn();
    }

    static getByID(id: string): PathType {
        for (const pathType of PathType.values()) {
            if (pathType.id === id)
                return pathType;
        }
        throw new Error(`Unknown path type ${id}`);
    }

    static getByIDOrNull(id: string | null): PathType | null {
        if (id === null)
            return null;

        for (const pathType of PathType.values()) {
            if (pathType.id === id)
                return pathType;
        }
        return null;
    }
}
