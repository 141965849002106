import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { GameState } from "@/ts/royalur/rules/state/GameState";
import { WaitingForRollGameState } from "@/ts/royalur/rules/state/WaitingForRollGameState";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Move } from "@/ts/royalur/model/Move";
import { RolledGameState } from "@/ts/royalur/rules/state/RolledGameState";
import { WaitingForMoveGameState } from "@/ts/royalur/rules/state/WaitingForMoveGameState";
import { MovedGameState } from "@/ts/royalur/rules/state/MovedGameState";
import { EndGameState } from "@/ts/royalur/rules/state/EndGameState";
import { FastGame } from "@/ts/royalur/rules/simple/fast/FastGame";
import { ActionGameState } from "@/ts/royalur/rules/state/ActionGameState";
import { PlayableGameState } from "@/ts/royalur/rules/state/PlayableGameState";
import { Roll } from "@/ts/royalur/model/dice/Roll";
import { ControlGameState } from "@/ts/royalur/rules/state/ControlGameState";


/**
 * The most common, simplified, rules of the Royal Game of Ur.
 * Any piece with a valid move can be moved. Rosettes give another
 * turn and are safe squares.
 */
export class SimpleRuleSet extends RuleSet {
    createCompatibleFastGame(): FastGame {
        const startingPieceCount = this.playerStateProvider.getStartingPieceCount();
        return new FastGame(this, startingPieceCount);
    }

    generateInitialGameState(): GameState {
        return new WaitingForRollGameState(
            new Board(this.settings.getBoardShape()),
            this.playerStateProvider.createStartingState(PlayerType.LIGHT),
            this.playerStateProvider.createStartingState(PlayerType.DARK),
            PlayerType.LIGHT,
        );
    }

    findAvailableMoves(board: Board, player: PlayerState, roll: Roll): Move[] {
        if (roll.value() <= 0)
            return [];

        const playerType = player.getPlayer();
        const path = this.getPaths().get(playerType);
        const moves: Move[] = [];

        // Check if a piece can be taken off the board.
        if (roll.value() <= path.length) {
            const scorePathIndex = path.length - roll.value();
            const scoreTile = path[scorePathIndex];
            const scorePiece = board.get(scoreTile);
            if (scorePiece !== null
                && scorePiece.getOwner() === playerType
                && scorePiece.getPathIndex() === scorePathIndex
            ) {
                moves.push(new Move(playerType, scoreTile, scorePiece, null, null, null));
            }
        }

        // Check for pieces on the board that can be moved to another tile on the board.
        for (let index = -1; index < path.length - roll.value(); ++index) {
            let tile;
            let piece;
            if (index >= 0) {
                // Move a piece on the board.
                tile = path[index];
                piece = board.get(tile);
                if (piece === null)
                    continue;
                if (piece.getOwner() !== playerType || piece.getPathIndex() !== index)
                    continue;
            } else if (player.getPieceCount() > 0) {
                // Introduce a piece to the board.
                tile = null;
                piece = null;
            } else {
                continue;
            }

            // Check if the destination is free.
            const destPathIndex = index + roll.value();
            const dest = path[destPathIndex];
            const destPiece = board.get(dest);
            if (destPiece !== null) {
                // Can't capture your own pieces.
                if (destPiece.getOwner() === playerType)
                    continue;

                // Can't capture pieces on rosettes if they are safe.
                if (this.areRosettesSafe() && board.getShape().isRosette(dest))
                    continue;
            }

            // Generate the move.
            let movedPiece;
            if (index >= 0) {
                // ESLint can't figure out that this is impossible.
                if (!piece)
                    throw new Error("Unexpected error: piece is null");

                movedPiece = this.pieceProvider.createMoved(piece, destPathIndex);
            } else {
                movedPiece = this.pieceProvider.createIntroduced(playerType, destPathIndex);
            }
            moves.push(new Move(playerType, tile, piece, dest, movedPiece, destPiece));
        }
        return moves;
    }

    applyRoll(
        state: WaitingForRollGameState,
        roll: Roll,
    ): GameState[] {
        // Construct the state representing the roll that was made.
        const availableMoves = this.findAvailableMoves(
            state.getBoard(), state.getTurnPlayer(), roll,
        );
        const rolledState = new RolledGameState(
            state.getBoard(),
            state.getLightPlayer(),
            state.getDarkPlayer(),
            state.getTurn(),
            roll,
            availableMoves,
        );

        // Swap turn when rolling a zero.
        if (roll.value() === 0) {
            const newTurn = state.getTurn().getOtherPlayer();
            return [rolledState, new WaitingForRollGameState(
                state.getBoard(),
                state.getLightPlayer(),
                state.getDarkPlayer(),
                newTurn,
            )];
        }

        // Determine if the player has no available moves.
        if (availableMoves.length === 0) {
            const newTurn = state.getTurn().getOtherPlayer();
            return [rolledState, new WaitingForRollGameState(
                state.getBoard(),
                state.getLightPlayer(),
                state.getDarkPlayer(),
                newTurn,
            )];
        }

        // The player has moves they can make.
        return [rolledState, new WaitingForMoveGameState(
            state.getBoard(),
            state.getLightPlayer(),
            state.getDarkPlayer(),
            state.getTurn(),
            roll,
            availableMoves,
        )];
    }

    /**
     * Determines whether the move represent by `movedState` should
     * grant another roll to the player that made the move.
     */
    shouldGrantRoll(movedState: MovedGameState): boolean {
        const move = movedState.getMove();

        if (this.doRosettesGrantExtraRolls()) {
            const boardShape = movedState.getBoard().getShape();
            if (move.isLandingOnRosette(boardShape))
                return true;
        }
        return this.doCapturesGrantExtraRolls() && move.isCapture();
    }

    applyMove(
        state: WaitingForMoveGameState,
        move: Move,
    ): GameState[] {
        // Generate the state representing the move that was made.
        const movedState = new MovedGameState(
            state.getBoard(), state.getLightPlayer(), state.getDarkPlayer(),
            state.getTurn(), state.getRoll(), move,
        );

        // Apply the move to the board.
        const board = state.getBoard().copy();
        move.apply(board);

        // Apply the move to the player that made the move.
        let turnPlayer = state.getTurnPlayer();
        if (move.isIntroduction()) {
            turnPlayer = this.playerStateProvider.applyPieceIntroduced(turnPlayer, move.getDestPiece());
        }
        if (move.isScore()) {
            turnPlayer = this.playerStateProvider.applyPieceScored(turnPlayer, move.getSourcePiece());
        }

        // Apply the effects of the move to the other player.
        let otherPlayer = state.getWaitingPlayer();
        if (move.isCapture()) {
            otherPlayer = this.playerStateProvider.applyPieceCaptured(otherPlayer, move.getCapturedPiece());
        }

        // Determine which player is which.
        const turn = state.getTurn();
        const lightPlayer = (turn === PlayerType.LIGHT ? turnPlayer : otherPlayer);
        const darkPlayer = (turn === PlayerType.DARK ? turnPlayer : otherPlayer);

        // Check if the player has won the game.
        const turnPlayerPieces = turnPlayer.getPieceCount();
        if (move.isScore() && turnPlayerPieces + board.countPieces(turn) <= 0)
            return [movedState, new EndGameState(
                board, lightPlayer, darkPlayer, turn,
            )];

        // Determine whose turn it will be in the next state.
        const nextTurn = (this.shouldGrantRoll(movedState) ? turn : turn.getOtherPlayer());
        return [movedState, new WaitingForRollGameState(
            board, lightPlayer, darkPlayer, nextTurn,
        )];
    }

    override selectLandmarkStates(states: GameState[]): GameState[] {
        const landmarkStates: GameState[] = [];
        let seenAction = false;
        for (let index = states.length; index >= 0; --index) {
            const state = states[index];

            // We always want to include the last action that was made in the game.
            if (state instanceof ActionGameState && !seenAction) {
                landmarkStates.push(state);
                seenAction = true;
                continue;
            }

            // Moves are important.
            if (state instanceof MovedGameState) {
                landmarkStates.push(state);
                continue;
            }

            // Include rolls that do not have a move state that describes them.
            if (state instanceof RolledGameState && state.getAvailableMoves().length === 0) {
                landmarkStates.push(state);
                continue;
            }

            // Always include control states.
            if (state instanceof ControlGameState) {
                landmarkStates.push(state);
                continue;
            }

            // Always include the initial state and the current state.
            if (index === 0 || index === states.length - 1) {
                landmarkStates.push(state);
            }
        }

        // We loop in reverse-order, so correct that.
        landmarkStates.reverse();

        if (!(landmarkStates[0] instanceof PlayableGameState))
            throw new Error("Sanity check failed: The first landmark state should be playable");

        return landmarkStates;
    }
}
