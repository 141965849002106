import { PlayerType } from "@/ts/royalur/model/PlayerType";


/**
 * A piece on a board.
 */
export class Piece {
    private readonly owner: PlayerType;
    private readonly pathIndex: number;

    constructor(owner: PlayerType, pathIndex: number) {
        if (pathIndex < -1)
            throw new Error("The path index cannot be less than -1: " + pathIndex);

        this.owner = owner;
        this.pathIndex = pathIndex;
    }

    /**
     * Gets the player that owns this piece.
     */
    getOwner(): PlayerType {
        return this.owner;
    }

    /**
     * Gets the path index of this piece.
     */
    getPathIndex(): number {
        return this.pathIndex;
    }

    equals(other: Piece | null): boolean {
        if (other === null)
            return false;

        return this.owner === other.owner
            && this.pathIndex === other.pathIndex;
    }

    toString(): string {
        return `(${this.owner.getName()}, advanced ${this.pathIndex + 1})`;
    }

    /**
     * Converts `piece` to a single character that can be used
     * to textually represent the owner of a piece.
     */
    static toChar(piece: Piece | null): string {
        return PlayerType.toChar(piece !== null ? piece.owner : null);
    }
}
