import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import {
    DescribePlayerNames,
    getDescribePlayerName,
} from "@/ts/royalur/rules/state/GameState";
import { ControlGameState } from "@/ts/royalur/rules/state/ControlGameState";


/**
 * A game state that represents a player resigning from a game.
 */
export class ResignedGameState extends ControlGameState {
    constructor(
        board: Board,
        lightPlayer: PlayerState,
        darkPlayer: PlayerState,
        player: PlayerType
    ) {
        super(board, lightPlayer, darkPlayer, player);
    }

    override describe(playerNames?: DescribePlayerNames): string {
        const playerName = getDescribePlayerName(this.getPlayer(), playerNames);
        return `${playerName} resigned`;
    }

    override equals(other: any): boolean {
        if (!other || this.constructor !== other.constructor)
            return false;
        if (!(other instanceof ResignedGameState))
            throw new Error("Same constructor, but instanceof returns false");

        return ControlGameState._baselineEquals(this, other);
    }
}
