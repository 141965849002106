import { Agent } from "@/ts/royalur/agent/Agent";
import { Dice } from "@/ts/royalur/model/dice/Dice";
import { FastMoveList } from "@/ts/royalur/rules/simple/fast/FastMoveList";
import { FastGame } from "@/ts/royalur/rules/simple/fast/FastGame";
import { SimpleRuleSet } from "@/ts/royalur/rules/simple/SimpleRuleSet";
import { UtilityFunction } from "@/ts/royalur/agent/utility/UtilityFunction";
import { Game } from "@/ts/royalur/Game";
import { Move } from "@/ts/royalur/model/Move";


/**
 * An agent that makes deterministic move choices for testing. This is not thread-safe.
 */
export class LikelihoodAgent extends Agent {
    /**
     * The rules used for games given to this agent.
     */
    private readonly rules: SimpleRuleSet;

    /**
     * The utility function to use to evaluate game states.
     */
    private readonly utilityFunction: UtilityFunction;

    /**
     * Any sequence of rolls that has a lower likelihood of
     * occurring than this will be ignored.
     */
    private readonly likelihoodThreshold: number;

    /**
     * Game objects used to hold the state of games
     * while exploring the game tree.
     */
    private readonly gameHolders: FastGame[];

    /**
     * Move lists used to hold available moves while
     * exploring the game tree.
     */
    private readonly moveListHolders: FastMoveList[];

    /**
     * Dice used to hold the state of dice while exploring
     * the game tree.
     */
    private readonly diceHolders: Dice[];

    constructor(
        rules: SimpleRuleSet,
        utilityFunction: UtilityFunction,
        likelihoodThreshold: number,
    ) {
        super();
        this.rules = rules;
        this.utilityFunction = utilityFunction;
        this.likelihoodThreshold = likelihoodThreshold;
        this.gameHolders = [];
        this.moveListHolders = [];
        this.diceHolders = [];
    }

    /**
     * Gets a holding object that can be used to store the state of a die,
     * and used to determine the available dice roll probabilities.
     */
    getGameHolder(depth: number): FastGame {
        while (this.gameHolders.length <= depth) {
            this.gameHolders.push(this.rules.createCompatibleFastGame());
        }
        return this.gameHolders[depth];
    }

    /**
     * Gets a holding object that can be used to store available moves.
     */
    getMoveListHolder(depth: number): FastMoveList {
        while (this.moveListHolders.length <= depth) {
            this.moveListHolders.push(new FastMoveList());
        }
        return this.moveListHolders[depth];
    }

    /**
     * Gets a holding object that can be used to store the state of a die,
     * and used to determine the available dice roll probabilities.
     */
    getDiceHolder(depth: number): Dice {
        while (this.diceHolders.length <= depth) {
            const dice = this.rules.getDiceFactory().createDice();
            this.diceHolders.push(dice);
        }
        return this.diceHolders[depth];
    }

    private calculateBestMoveUtility(
        precedingGame: FastGame,
        availableMoves: FastMoveList,
        dice: Dice,
        likelihood: number,
        depth: number,
    ): number {
        if (!precedingGame.isWaitingForMove())
            throw new Error("Game is not waiting for a move");

        let maxUtility = Number.NEGATIVE_INFINITY;

        const moves = availableMoves.moves;
        const moveCount = availableMoves.moveCount;

        const game = this.getGameHolder(depth);

        for (let moveIndex = 0; moveIndex < moveCount; ++moveIndex) {
            game.copyFrom(precedingGame);
            game.applyMove(moves[moveIndex]);

            let utility = this.calculateProbabilityWeightedUtility(
                game, dice, likelihood, depth + 1,
            );
            if (game.isLightTurn !== precedingGame.isLightTurn) {
                utility = -utility;
            }
            if (utility > maxUtility) {
                maxUtility = utility;
            }
        }
        return maxUtility;
    }

    private calculateProbabilityWeightedUtility(
        precedingGame: FastGame,
        precedingDice: Dice,
        likelihood: number,
        depth: number,
    ): number {
        if (precedingGame.isFinished)
            return this.utilityFunction.scoreGame(precedingGame);
        if (!precedingGame.isWaitingForRoll())
            throw new Error("Game is not waiting for a roll of the dice");

        let utility = 0.0;
        const probabilities = precedingDice.getRollProbabilities();
        const likelihoodThreshold = this.likelihoodThreshold;

        const game = this.getGameHolder(depth);
        const moveList = this.getMoveListHolder(depth);
        const dice = this.getDiceHolder(depth);

        let currentUtilityGenerated = false;
        let currentUtility = 0.0;

        for (let roll = 0; roll < probabilities.length; ++roll) {
            const prob = probabilities[roll];
            if (prob === 0.0)
                continue;

            // Update the state of the dice.
            dice.copyFrom(precedingDice);
            dice.recordRoll(roll);
            const rollLikelihood = prob * likelihood;

            let rollUtility;
            if (rollLikelihood < likelihoodThreshold) {
                if (!currentUtilityGenerated) {
                    currentUtility = this.utilityFunction.scoreGame(precedingGame);
                    currentUtilityGenerated = true;
                }
                rollUtility = currentUtility;
            } else {
                // Update the state of the game.
                game.copyFrom(precedingGame);
                game.applyRoll(roll, moveList);
                if (moveList.moveCount === 1) {
                    game.applyMove(moveList.moves[0]);
                }

                // Recurse!
                if (!game.isWaitingForMove()) {
                    rollUtility = this.calculateProbabilityWeightedUtility(
                        game, dice, rollLikelihood, depth + 1,
                    );
                } else {
                    rollUtility = this.calculateBestMoveUtility(
                        game, moveList, dice, rollLikelihood, depth + 1,
                    );
                }
                if (game.isLightTurn !== precedingGame.isLightTurn) {
                    rollUtility = -rollUtility;
                }
            }
            utility += prob * rollUtility;
        }
        return utility;
    }

    scoreMoves(game: Game, moves: Move[]): number[] {
        if (moves.length === 0)
            throw new Error("No moves available");
        if (moves.length === 1)
            return [0];

        const moveUtilities: number[] = [];
        let bestUtility = 0.0;

        const gameHolder = this.getGameHolder(0);
        const diceHolder = this.getDiceHolder(0);

        for (let index = 0; index < moves.length; ++index) {
            const move = moves[index];
            const newGame = game.copy();
            newGame.move(move);
            const swappedTurn = (game.getTurn() !== newGame.getTurn());

            gameHolder.copyFromGame(newGame);
            diceHolder.copyFrom(newGame.getDice());

            let utility = this.calculateProbabilityWeightedUtility(
                gameHolder, diceHolder, 1.0, 1,
            );
            if (swappedTurn) {
                utility = -utility;
            }

            moveUtilities[index] = utility;
            bestUtility = Math.max(bestUtility, utility);
        }
        return moveUtilities;
    }

    override decideMove(game: Game, moves: Move[]): Move {
        if (moves.length === 0)
            throw new Error("No moves available");
        if (moves.length === 1)
            return moves[0];

        const moveUtilities = this.scoreMoves(game, moves);

        let bestMove: Move = moves[0];
        let bestUtility = moveUtilities[0];

        for (let index = 1; index < moves.length; ++index) {
            const move = moves[index];
            const utility = moveUtilities[index];

            if (utility > bestUtility) {
                bestMove = move;
                bestUtility = utility;
            }
        }
        return bestMove;
    }
}
