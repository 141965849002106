import { BasicRoll } from "@/ts/royalur/model/dice/BasicRoll";
import { Roll } from "@/ts/royalur/model/dice/Roll";
import { BinaryDice } from "@/ts/royalur/model/dice/BinaryDice";


/**
 * A set of binary dice where a roll of zero actually represents
 * the highest roll possible, rather than the lowest.
 */
export class BinaryDice0AsMax extends BinaryDice {

    /**
     * The maximum value that can be rolled by this dice.
     */
    private readonly maxRollValue: number;

    /**
     * The probability of rolling each value with these dice.
     */
    private readonly rollProbabilities0AsMax: number[];

    /**
     * Instantiates this binary dice with `random` as the source
     * of randomness to generate rolls.
     */
    constructor(id: string, numDie: number) {
        super(id, numDie);
        this.maxRollValue = numDie + 1;
        this.rollProbabilities0AsMax = [];

        // Move the probability of 0 to the max roll slot.
        const dist = super.getRollProbabilities();
        this.rollProbabilities0AsMax.push(0.0);
        for (let roll = 1; roll <= numDie; ++roll) {
            this.rollProbabilities0AsMax.push(dist[roll]);
        }
        this.rollProbabilities0AsMax.push(dist[0]);
    }

    override getMaxRollValue(): number {
        return this.maxRollValue;
    }

    override getRollProbabilities(): number[] {
        return this.rollProbabilities0AsMax;
    }

    override rollValue(): number {
        const value = super.rollValue();
        return value > 0 ? value : this.maxRollValue;
    }

    override generateRoll(value: number): Roll {
        if (value <= 0 || value > this.getMaxRollValue())
            throw new Error("This dice cannot roll " + value);

        return BasicRoll.of(value);
    }
}
