import { PathPair } from "@/ts/royalur/model/path/PathPair";
import { Tile } from "@/ts/royalur/model/Tile";
import { PathType } from "@/ts/royalur/model/path/PathType";


/**
 * The paths proposed by Masters for the Royal Game of Ur.
 * <p>
 * Citation: J. Masters, The Royal Game of Ur &amp; The Game of 20 Squares (2021).
 * Available at
 * <a href="https://www.tradgames.org.uk/games/Royal-Game-Ur.htm">
 *     https://www.tradgames.org.uk/games/Royal-Game-Ur.htm
 * </a>.
 */
export class MastersPathPair extends PathPair {
    public static LIGHT_PATH: Tile[] = Tile.createPath(
        [1, 5],
        [1, 1],
        [2, 1],
        [2, 7],
        [3, 7],
        [3, 8],
        [1, 8],
        [1, 6],
    );

    public static DARK_PATH: Tile[] = Tile.createPath(
        [3, 5],
        [3, 1],
        [2, 1],
        [2, 7],
        [1, 7],
        [1, 8],
        [3, 8],
        [3, 6],
    );

    constructor() {
        super(PathType.MASTERS.getID(), MastersPathPair.LIGHT_PATH, MastersPathPair.DARK_PATH);
    }
}
