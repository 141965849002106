import { BoardShape } from "@/ts/royalur/model/shape/BoardShape";
import { AsebPathPair } from "@/ts/royalur/model/path/AsebPathPair";
import { Tile } from "@/ts/royalur/model/Tile";
import { BoardType } from "@/ts/royalur/model/shape/BoardType";


/**
 * The shape of the board used for the game Aseb.
 */
export class AsebBoardShape extends BoardShape {
    public static BOARD_TILES: Tile[] = Tile.unionLists(
        new AsebPathPair().getLight(),
        new AsebPathPair().getDark(),
    );

    public static ROSETTE_TILES: Tile[] = Tile.createList(
        [1, 1],
        [3, 1],
        [2, 4],
        [2, 8],
        [2, 12],
    );

    constructor() {
        super(
            BoardType.ASEB.getID(),
            AsebBoardShape.BOARD_TILES,
            AsebBoardShape.ROSETTE_TILES,
        );
    }
}
