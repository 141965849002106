import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { GameState } from "@/ts/royalur/rules/state/GameState";
import { Board } from "@/ts/royalur/model/Board";


/**
 * A game state from within a game where a winner has not yet been determined.
 */
export abstract class OngoingGameState extends GameState {
    private readonly turn: PlayerType;

    constructor(
        board: Board,
        lightPlayer: PlayerState,
        darkPlayer: PlayerState,
        turn: PlayerType,
    ) {
        super(board, lightPlayer, darkPlayer);
        this.turn = turn;
    }

    override isFinished(): boolean {
        return false;
    }

    override getSubject(): PlayerType {
        return this.turn;
    }

    /**
     * Gets the player who can make the next interaction with the game.
     */
    getTurn(): PlayerType {
        return this.turn;
    }

    /**
     * Gets the player that is waiting for their own turn.
     */
    getWaiting(): PlayerType {
        return this.turn.getOtherPlayer();
    }

    /**
     * Retrieves the state of the player that we are waiting on to interact with the game.
     */
    getTurnPlayer(): PlayerState {
        return this.getPlayerState(this.turn);
    }

    /**
     * Retrieves the state of the player that is waiting for their own turn.
     */
    getWaitingPlayer(): PlayerState {
        return this.getPlayerState(this.getWaiting());
    }

    /**
     * Returns whether the baseline OngoingGameState values are equal
     * between the two provided game states.
     */
    static _baselineEquals(one: OngoingGameState, two: OngoingGameState): boolean {
        return GameState._baselineEquals(one, two) && one.turn === two.turn;
    }
}
