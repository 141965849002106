

/**
 * A tile represents location on a Royal Game of Ur board.
 */
export class Tile {
    private readonly x: number;
    private readonly y: number;

    private readonly ix: number;
    private readonly iy: number;

    constructor(x: number, y: number) {
        if (!Number.isInteger(x))
            throw new Error("x is not an integer: " + x);
        if (!Number.isInteger(y))
            throw new Error("y is not an integer: " + y);
        if (x < 1 || x > 26)
            throw new Error("x must fall within the range [1, 26]. Invalid value: " + x);
        if (y < 0)
            throw new Error("y must not be negative. Invalid value: " + y);

        this.x = x;
        this.y = y;

        this.ix = x - 1;
        this.iy = y - 1;
    }

    /**
     * Gets the x-coordinate of the tile. This coordinate is 1-based.
     */
    getX(): number {
        return this.x;
    }

    /**
     * Gets the x-index of the tile. This coordinate is 0-based.
     */
    getXIndex(): number {
        return this.ix;
    }

    /**
     * Gets the y-coordinate of the tile. This coordinate is 1-based.
     */
    getY(): number {
        return this.y;
    }

    /**
     * Gets the y-index of the tile. This coordinate is 0-based.
     */
    getYIndex(): number {
        return this.iy;
    }

    /**
     * Creates a new tile representing the tile at the
     * indices (ix, iy), 0-based.
     */
    static fromIndices(ix: number, iy: number): Tile {
        return new Tile(ix + 1, iy + 1);
    }

    /**
     * Determines whether this tile and `other` refer to the same tile.
     */
    equals(other: any): boolean {
        if (!other || this.constructor !== other.constructor)
            return false;
        if (!(other instanceof Tile))
            throw new Error("Same constructor, but instanceof returns false");

        return this.x == other.x && this.y == other.y;
    }

    /**
     * Checks whether tile1 and tile2 are the same value.
     */
    static areEqual(tile1: Tile | null | undefined, tile2: Tile | null | undefined): boolean {
        if (tile1 === tile2)
            return true;
        if (!tile1 || !tile2)
            return false;
        return tile1.equals(tile2);
    }

    /**
     * Takes a unit length step towards the other tile.
     */
    stepTowards(other: Tile): Tile {
        const dx = other.x - this.x;
        const dy = other.y - this.y;
        if (Math.abs(dx) + Math.abs(dy) <= 1)
            return other;

        if (Math.abs(dx) < Math.abs(dy)) {
            return new Tile(this.x, this.y + (dy > 0 ? 1 : -1));
        } else {
            return new Tile(this.x + (dx > 0 ? 1 : -1), this.y);
        }
    }

    /**
     * Encodes the x-coordinate as an upper-case letter.
     */
    encodeX(): string {
        return String.fromCharCode("A".charCodeAt(0) + (this.x - 1));
    }

    /**
     * Encodes the x-coordinate as a lower-case letter.
     */
    encodeXLowerCase(): string {
        return String.fromCharCode("a".charCodeAt(0) + (this.x - 1));
    }

    /**
     * Encodes the y-coordinate as a number.
     */
    encodeY(): string {
        return this.y.toString();
    }

    /**
     * Converts the location of this tile into a text representation of the
     * format "[letter][number]".
     */
    toString(): string {
        return this.encodeX() + this.encodeY();
    }

    /**
     * Converts text representations of the tile of the format "[letter][number]".
     * For example:
     *  - A1 represents (1, 1)
     *  - C3 represents (3, 3)
     *  - B8 represents (2, 8)
     *  - B0 represents (2, 0)
     */
    static fromString(tile: string): Tile {
        if (tile.length < 2)
            throw new Error("Expected a letter followed by a number");

        const xChar = tile.charCodeAt(0);
        let x: number;
        if (xChar >= "a".charCodeAt(0) && xChar <= "z".charCodeAt(0)) {
            x = xChar - "a".charCodeAt(0) + 1;
        } else if (xChar >= "A".charCodeAt(0) && xChar <= "Z".charCodeAt(0)) {
            x = xChar - "A".charCodeAt(0) + 1;
        } else {
            throw new Error("Illegal letter representing the x-coordinate: " + xChar);
        }

        const y: number = parseInt(tile.substring(1));
        return new Tile(x, y);
    }


    /**
     * Finds the index of tile in tiles. If tile is not found
     * in tiles, then -1 is returned.
     */
    static listIndexOf(tiles: Tile[], tile: Tile): number {
        for (let index = 0; index < tiles.length; ++index) {
            if (tile.equals(tiles[index]))
                return index;
        }
        return -1;
    }

    /**
     * Determines whether tiles contains tile.
     */
    static listContains(tiles: Tile[], tile: Tile): boolean {
        return Tile.listIndexOf(tiles, tile) !== -1;
    }

    /**
     * Construct a list of tiles from a list of tuples of coordinates
     * in the form [[x1, y1], [x2, y2], ..., [xn, yn]].
     */
    static createList(...args: [number, number][]): Tile[] {
        const tiles: Tile[] = [];
        for (const loc of args) {
            tiles.push(new Tile(loc[0], loc[1]));
        }
        return tiles;
    }

    /**
     * Calculates the union of all given tile lists.
     */
    static unionLists(...args: Tile[][]): Tile[] {
        const tiles: Tile[] = [];
        for (const tileList of args) {
            for (const tile of tileList) {
                if (!Tile.listContains(tiles, tile)) {
                    tiles.push(tile);
                }
            }
        }
        return tiles;
    }

    /**
     * Constructs a path of tiles from a list of coordinates of waypoints
     * on the path in the form [[x1, y1], [x2, y2], ..., [xn, yn]].
     */
    static createPath(...args: [number, number][]): Tile[] {
        const waypoints: Tile[] = Tile.createList(...args);

        let curr: Tile = waypoints[0];
        const path: Tile[] = [curr];
        for (let index = 1; index < waypoints.length; ++index) {
            const next = waypoints[index];
            while (!curr.equals(next)) {
                curr = curr.stepTowards(next);
                path.push(curr);
            }
        }
        return path;
    }

    /**
     * Sorts in ascending order of y, and then tiles with
     * the same y are sorted in ascending order of x.
     */
    static sortList(tiles: Tile[]) {
        tiles.sort((a, b) => a.y === b.y ? a.x - b.x : a.y - b.y);
    }

    /**
     * Returns whether the two lists contain the same tiles,
     * in the same order.
     */
    static listEquals(tiles1: Tile[], tiles2: Tile[]): boolean {
        if (tiles1.length !== tiles2.length)
            return false;

        for (let index = 0; index < tiles1.length; ++index) {
            if (!tiles1[index].equals(tiles2[index]))
                return false;
        }
        return true;
    }

    /**
     * Returns whether the two sets contain the same tiles.
     */
    static setEquals(tiles1: Tile[], tiles2: Tile[]): boolean {
        for (const tile1 of tiles1) {
            if (!Tile.listContains(tiles2, tile1))
                return false;
        }
        return true;
    }
}
