import { BellPathPair } from "@/ts/royalur/model/path/BellPathPair";
import { BoardShape } from "@/ts/royalur/model/shape/BoardShape";
import { Tile } from "@/ts/royalur/model/Tile";
import { BoardType } from "@/ts/royalur/model/shape/BoardType";


/**
 * The simple shape of board used for The Royal Game of Ur that
 * follows the game boards that were excavated by Sir Leonard Woolley.
 */
export class StandardBoardShape extends BoardShape {
    public static BOARD_TILES: Tile[] = Tile.unionLists(
        new BellPathPair().getLight(),
        new BellPathPair().getDark(),
    );

    public static ROSETTE_TILES: Tile[] = Tile.createList(
        [1, 1],
        [3, 1],
        [2, 4],
        [1, 7],
        [3, 7],
    );

    constructor() {
        super(
            BoardType.STANDARD.getID(),
            StandardBoardShape.BOARD_TILES,
            StandardBoardShape.ROSETTE_TILES,
        );
    }
}
