import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { PlayerType } from "@/ts/royalur/model/PlayerType";


export class FastPlayer {
    /**
     * The tile indices of each tile on the path for this player.
     */
    public readonly path: number[];

    /**
     * The sign of this player for pieces.
     * Light is +1, dark is -1.
     */
    public readonly sign: number;
    public readonly isLight: boolean;

    public pieces: number;
    public score: number;

    /**
     * Initializes a FastPlayer with the given path and light status.
     */
    constructor(path: number[], isLight: boolean) {
        this.path = path;
        this.sign = isLight ? 1 : -1;
        this.isLight = isLight;
        this.pieces = 0;
        this.score = 0;
    }

    public copyFrom(other: FastPlayer): void {
        if (this.sign !== other.sign) {
            throw new Error("Different player!");
        }
        this.pieces = other.pieces;
        this.score = other.score;
    }

    public copyFromState(state: PlayerState): void {
        const expectedPlayerType = this.isLight ? PlayerType.LIGHT : PlayerType.DARK;
        if (expectedPlayerType !== state.getPlayer())
            throw new Error("Different player!");

        this.pieces = state.getPieceCount();
        this.score = state.getScore();
    }
}
