import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";


export interface DescribePlayerNames {
    lightName: string;
    darkName: string;
}

export const defaultDescribePlayerNames: DescribePlayerNames = {
    lightName: "Light",
    darkName: "Dark",
};

export function getDescribePlayerName(
    player: PlayerType, names?: DescribePlayerNames,
) {
    names ??= defaultDescribePlayerNames;
    return (player === PlayerType.LIGHT ? names.lightName : names.darkName);
}


/**
 * A game state represents a single point within a game.
 */
export abstract class GameState {
    private readonly board: Board;
    private readonly lightPlayer: PlayerState;
    private readonly darkPlayer: PlayerState;

    protected constructor(board: Board, lightPlayer: PlayerState, darkPlayer: PlayerState) {
        this.board = board;
        this.lightPlayer = lightPlayer;
        this.darkPlayer = darkPlayer;
    }

    /**
     * Get the state of the pieces on the board.
     */
    getBoard(): Board {
        return this.board;
    }

    /**
     * Get the state of the light player.
     */
    getLightPlayer(): PlayerState {
        return this.lightPlayer;
    }

    /**
     * Get the state of the dark player.
     */
    getDarkPlayer(): PlayerState {
        return this.darkPlayer;
    }

    /**
     * Retrieves the state of the given player.
     */
    getPlayerState(player: PlayerType): PlayerState {
        switch (player) {
            case PlayerType.LIGHT:
                return this.getLightPlayer();
            case PlayerType.DARK:
                return this.getDarkPlayer();
            default:
                throw new Error("Unknown player " + player.getName());
        }
    }

    /**
     * Get the subject player of the game state. e.g., player
     * to roll/move, player that rolled/moved, player that won.
     */
    abstract getSubject(): PlayerType | null;

    /**
     * Returns whether this state is a valid state to be played from.
     */
    abstract isPlayable(): boolean;

    /**
     * Returns whether this state represents a finished game.
     */
    abstract isFinished(): boolean;

    /**
     * Generates an English text description of the state of the game.
     */
    abstract describe(playerNames?: DescribePlayerNames): string;

    /**
     * Returns whether this represents the same state as other.
     */
    abstract equals(other: GameState): boolean;

    /**
     * Returns whether the baseline GameState values are equal
     * between the two provided game states.
     */
    static _baselineEquals(one: GameState, two: GameState): boolean {
        return one.board.equals(two.board)
            && one.lightPlayer.equals(two.lightPlayer)
            && one.darkPlayer.equals(two.darkPlayer);
    }
}
