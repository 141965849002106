import { UtilityFunction } from "@/ts/royalur/agent/utility/UtilityFunction";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { FastGame } from "@/ts/royalur/rules/simple/fast/FastGame";


/**
 * Scores game states based upon how far pieces have been advanced
 * by each player.
 */
export class PiecesAdvancedUtilityFn extends UtilityFunction {
    private readonly scoredPieceUtility: number;

    constructor(rules: RuleSet) {
        super();
        const pathLength = rules.getPaths().getLight().length;
        if (pathLength !== rules.getPaths().getDark().length) {
            throw new Error("Paths of different sizes for light and dark are not supported");
        }
        this.scoredPieceUtility = pathLength + 1;
    }

    scoreGameStateForLight(game: FastGame): number {
        let utility = (game.light.score - game.dark.score) * this.scoredPieceUtility;
        const boardPieces = game.board.pieces;

        for (const boardPiece of boardPieces) {
            // The board piece is already positive for light, negative for dark,
            // and has a magnitude equal to how far each piece has moved.
            utility += boardPiece;
        }
        return utility;
    }
}
