import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import {
    DescribePlayerNames,
    getDescribePlayerName,
} from "@/ts/royalur/rules/state/GameState";
import { ControlGameState } from "@/ts/royalur/rules/state/ControlGameState";
import { AbandonReason } from "@/ts/royalur/model/AbandonReason";


/**
 * A game state that represents a game being abandoned.
 */
export class AbandonedGameState extends ControlGameState {
    private readonly reason: AbandonReason;

    constructor(
        board: Board,
        lightPlayer: PlayerState,
        darkPlayer: PlayerState,
        reason: AbandonReason,
        player: PlayerType | null
    ) {
        super(board, lightPlayer, darkPlayer, player);
        if (reason.requiresPlayer() && player === null)
            throw new Error(`${reason.getName()} abandonment requires a player`);

        this.reason = reason;
    }

    getReason(): AbandonReason {
        return this.reason;
    }

    override describe(playerNames?: DescribePlayerNames): string {
        const playerName = (
            this.reason.requiresPlayer()
                ? getDescribePlayerName(this.getPlayer(), playerNames)
                : null
        );
        if (this.reason === AbandonReason.PLAYER_LEFT)
            return `${playerName} left the game`;
        if (this.reason === AbandonReason.EXTERNAL)
            return "An external event ended the game";

        throw new Error(`Unknown abandon reason ${this.reason.getName()}`);
    }

    override equals(other: any): boolean {
        if (!other || this.constructor !== other.constructor)
            return false;
        if (!(other instanceof AbandonedGameState))
            throw new Error("Same constructor, but instanceof returns false");

        return ControlGameState._baselineEquals(this, other);
    }
}
