import { Tile } from "@/ts/royalur/model/Tile";
import { Piece } from "@/ts/royalur/model/Piece";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { BoardShape } from "@/ts/royalur/model/shape/BoardShape";
import { PathPair } from "@/ts/royalur/model/path/PathPair";


/**
 * A move that can be made on a board.
 */
export class Move {
    private readonly player: PlayerType;

    private readonly from: Tile | null;
    private readonly fromPiece: Piece | null;

    private readonly to: Tile | null;
    private readonly toPiece: Piece | null;

    private readonly capturedPiece: Piece | null;

    constructor(
        player: PlayerType,
        from: Tile | null, fromPiece: Piece | null,
        to: Tile | null, toPiece: Piece | null,
        capturedPiece: Piece | null,
    ) {
        if ((from === null) !== (fromPiece === null))
            throw new Error("from and fromPiece must either be both null, or both non-null");
        if ((to === null) !== (toPiece === null))
            throw new Error("from and fromPiece must either be both null, or both non-null");
        if (to === null && capturedPiece !== null)
            throw new Error("Scoring moves cannot capture a piece");

        this.player = player;
        this.from = from;
        this.fromPiece = fromPiece;
        this.to = to;
        this.toPiece = toPiece;
        this.capturedPiece = capturedPiece;
    }

    /**
     * Gets the instigator of this move.
     */
    getPlayer(): PlayerType {
        return this.player;
    }

    /**
     * Determines whether this move is moving a piece on the board. If a move
     * introduces a new piece to the board, then it won't have a source piece.
     */
    hasSource(): boolean {
        return this.from !== null;
    }

    /**
     * Determines whether this move is moving a new piece onto the board.
     */
    isIntroduction(): boolean {
        return this.from === null;
    }

    /**
     * Determines whether this moves a piece to a destination on the board.
     * If a piece is being scored, then it won't have a destination.
     */
    hasDest(): boolean {
        return this.to !== null;
    }

    /**
     * Determines whether this move is moving a piece off of the board.
     */
    isScore(): boolean {
        return this.to === null;
    }

    /**
     * Determines whether this move is capturing an existing piece on the board.
     */
    isCapture(): boolean {
        return this.capturedPiece !== null;
    }

    /**
     * Determines whether this move will land a piece on a rosette. Under common
     * rule sets, this will give another turn to the player.
     */
    isLandingOnRosette(boardShape: BoardShape): boolean {
        return this.to !== null && boardShape.isRosette(this.to);
    }

    /**
     * Retrieves the source tile of this move. If there is no source tile, in the
     * case where a new piece is moved onto the board, this will throw an error
     * unless paths are provided. If paths is provided, it will provide the off-board
     * start tile from the path associated with the player making this move.
     */
    getSource(paths?: PathPair): Tile {
        if (this.from !== null)
            return this.from;

        if (!paths) {
            throw new Error(
                "This move has no source, as it is introducing a piece. "
                + "Provide paths if you wish to get an off-board tile in this case.",
            );
        }
        return paths.getStart(this.player);
    }

    getSourceOrNull(): Tile | null {
        return this.from;
    }

    /**
     * Retrieves the source piece of this move. If there is no source piece, in the
     * case where a new piece is moved onto the board, this will throw an error.
     */
    getSourcePiece(): Piece {
        if (this.fromPiece === null)
            throw new Error("This move has no source, as it is introducing a piece");

        return this.fromPiece;
    }

    getSourcePieceOrNull(): Piece | null {
        return this.fromPiece;
    }

    /**
     * Retrieves the destination tile of this move. If there is no destination tile,
     * in the case where a piece is moved off the board, this will throw an error
     * unless paths are provided. If paths is provided, it will provide the off-board
     * start tile from the path associated with the player making this move.
     */
    getDest(paths?: PathPair): Tile {
        if (this.to !== null)
            return this.to;

        if (!paths) {
            throw new Error(
                "This move has no destination, as it is scoring a piece. "
                + "Provide paths if you wish to get an off-board tile in this case.",
            );
        }
        return paths.getEnd(this.player);
    }

    getDestOrNull(): Tile | null {
        return this.to;
    }

    /**
     * Retrieves the destination piece of this move. If there is no destination piece,
     * in the case where a piece is moved off the board, this will throw an error.
     */
    getDestPiece(): Piece {
        if (this.toPiece === null)
            throw new Error("This move has no destination, as it is scoring a piece");

        return this.toPiece;
    }

    getDestPieceOrNull(): Piece | null {
        return this.toPiece;
    }

    /**
     * Retrieves the piece that will be captured by this move. If there is no piece
     * that will be displayed, this will throw an error.
     */
    getCapturedPiece(): Piece {
        if (this.capturedPiece == null)
            throw new Error("This move does not capture a piece");

        return this.capturedPiece;
    }

    getCapturedPieceOrNull(): Piece | null {
        return this.capturedPiece;
    }

    /**
     * Retrieves the length of this move as the number of tiles traversed.
     */
    getLength(paths: PathPair) {
        const source = this.fromPiece;
        const dest = this.toPiece;
        if (source && dest)
            return dest.getPathIndex() - source.getPathIndex();

        const path = paths.getWithStartEnd(this.player);
        const sourceIndex = (source ? source.getPathIndex() + 1 : 0);
        const destIndex = (dest ? dest.getPathIndex() + 1 : path.length - 1);
        return destIndex - sourceIndex;
    }

    /**
     * Apply this move to update the board `board`.
     */
    apply(board: Board) {
        if (this.from !== null) {
            board.set(this.from, null);
        }
        if (this.to !== null) {
            board.set(this.to, this.toPiece);
        }
    }

    /**
     * Generates an English description of this move.
     */
    describe(): string {
        const scoring = this.isScore();
        const introducing = this.isIntroduction();

        if (scoring && introducing)
            return "Introduce and score a piece.";

        if (scoring)
            return "Score a piece from " + this.getSource().toString() + ".";

        let desc;
        if (introducing) {
            desc = "Introduce a piece to ";
        } else {
            desc = "Move " + this.getSource().toString() + " to ";
        }
        if (this.isCapture()) {
            desc += "capture ";
        }
        desc += this.getDest().toString() + ".";
        return desc;
    }

    equals(other: any): boolean {
        if (!other || this.constructor !== other.constructor)
            return false;
        if (!(other instanceof Move))
            throw new Error("Same constructor, but instanceof returns false");

        if (this.player !== other.player)
            return false;
        if ((this.from === null) !== (other.from === null))
            return false;
        if ((this.fromPiece === null) !== (other.fromPiece === null))
            return false;
        if ((this.to === null) !== (other.to === null))
            return false;
        if ((this.toPiece === null) !== (other.toPiece === null))
            return false;
        if ((this.capturedPiece === null) !== (other.capturedPiece === null))
            return false;

        return (this.from === null || this.from.equals(other.from))
            && (this.fromPiece === null || this.fromPiece.equals(other.fromPiece))
            && (this.to === null || this.to.equals(other.to))
            && (this.toPiece === null || this.toPiece.equals(other.toPiece))
            && (this.capturedPiece === null || this.capturedPiece.equals(other.capturedPiece));
    }

    static listContains(moves: Move[], move: Move): boolean {
        for (const potentialMove of moves) {
            if (potentialMove.equals(move))
                return true;
        }
        return false;
    }

    static setEquals(moves1: Move[], moves2: Move[]): boolean {
        for (const move1 of moves1) {
            if (!Move.listContains(moves2, move1))
                return false;
        }
        return true;
    }

    /**
     * Finds a move from the list with the given source piece.
     */
    static findMoveBySourcePiece(moves: Move[], piece: Piece): Move | null {
        for (const move of moves) {
            if (move.getSourcePiece().equals(piece))
                return move;
        }
        return null;
    }

    /**
     * Finds a move from the list with the given source piece.
     */
    static findMoveBySourceTile(
        moves: Move[], sourceTile: Tile, paths: PathPair,
    ): Move | null {
        for (const move of moves) {
            if (move.isIntroduction()) {
                const startTile = paths.getStart(move.getPlayer());
                if (startTile.equals(sourceTile))
                    return move;
            } else if (move.getSource().equals(sourceTile)) {
                return move;
            }
        }
        return null;
    }

    /**
     * Attempts to find a move introducing a piece.
     */
    static findIntroductionMove(
        moves: Move[],
    ): Move | null {
        for (const move of moves) {
            if (move.isIntroduction())
                return move;
        }
        return null;
    }
}
