
export class TimeControl {
    public static readonly NONE = new TimeControl(0);

    /**
     * It is important that these are ordered from longest to shortest suffix.
     */
    private static readonly SECONDS_SUFFIXES = [
        " seconds", " second", "seconds", "second", " secs", " sec", "secs", "sec", " s", "s",
    ];

    private readonly perMoveSeconds: number;

    constructor(perMoveSeconds: number) {
        if (perMoveSeconds < 0)
            throw new Error("perMoveSeconds must be >= 0");

        this.perMoveSeconds = perMoveSeconds;
    }

    public static withPerMoveSeconds(perMoveSeconds: number): TimeControl {
        return new TimeControl(perMoveSeconds);
    }

    hasPerMoveSeconds(): boolean {
        return this.perMoveSeconds > 0;
    }

    getPerMoveSeconds(): number {
        if (this.perMoveSeconds === 0)
            throw new Error("Time control does not have a per move time");
        return this.perMoveSeconds;
    }

    equals(other: TimeControl): boolean {
        return this.perMoveSeconds === other.perMoveSeconds;
    }

    toString(): string {
        if (this.perMoveSeconds === 0)
            return "no time control";
        if (this.perMoveSeconds === 1)
            return "1 second per move";
        return `${this.perMoveSeconds} seconds per move`;
    }

    static parseSeconds(text: string): number {
        for (const suffix in TimeControl.SECONDS_SUFFIXES) {
            if (text.endsWith(suffix)) {
                const numberText = text.substring(0, text.length - suffix.length);
                return parseInt(numberText);
            }
        }
        throw new Error("Unable to recognise time duration: " + text);
    }

    static fromString(text: string): TimeControl {
        if (text === "no time control")
            return TimeControl.NONE;

        if (text.endsWith(" per move")) {
            const perMoveText = text.substring(0, text.length - " per move".length);
            const perMoveSeconds = TimeControl.parseSeconds(perMoveText);
            return new TimeControl(perMoveSeconds);
        }
        throw new Error("Unable to recognise time control: " + text);
    }
}
