import { PathPair } from "@/ts/royalur/model/path/PathPair";
import { Tile } from "@/ts/royalur/model/Tile";
import { PathType } from "@/ts/royalur/model/path/PathType";


/**
 * The simple paths that are used for Aseb.
 * <p>
 * Citation: W. Crist, A.E. Dunn-Vaturi, and A. de Voogt,
 * Ancient Egyptians at Play: Board Games Across Borders,
 * Bloomsbury Egyptology, Bloomsbury Academic, London, 2016.
 */
export class AsebPathPair extends PathPair {
    public static LIGHT_PATH: Tile[] = Tile.createPath(
        [1, 5],
        [1, 1],
        [2, 1],
        [2, 12],
        [1, 12],
    );

    public static DARK_PATH: Tile[] = Tile.createPath(
        [3, 5],
        [3, 1],
        [2, 1],
        [2, 12],
        [3, 12],
    );

    constructor() {
        super(PathType.ASEB.getID(), AsebPathPair.LIGHT_PATH, AsebPathPair.DARK_PATH);
    }
}
