import { BoardShape } from "@/ts/royalur/model/shape/BoardShape";
import { Board } from "@/ts/royalur/model/Board";
import { Piece } from "@/ts/royalur/model/Piece";
import { PlayerType } from "@/ts/royalur/model/PlayerType";

/**
 * The board of a simple game that is optimised for speed.
 * This speed comes at the cost of error checking and convenience.
 */
export class FastBoard {
    public readonly shape: BoardShape;
    public readonly width: number;
    public readonly height: number;

    /**
     * The pieces that are on all tiles of this board.
     * Indexed by ix + iy * width. Empty tiles are zero.
     * Light tiles are positive path indices + 1. Dark
     * tiles are negative path indices - 1.
     */
    public readonly pieces: number[];
    public readonly rosetteTiles: number[];

    constructor(shape: BoardShape) {
        this.shape = shape;
        this.width = shape.getWidth();
        this.height = shape.getHeight();
        this.pieces = new Array(this.width * this.height).fill(0);
        this.rosetteTiles = shape.getRosettes().map((tile) => {
            return this.calcTileIndex(tile.getXIndex(), tile.getYIndex());
        });
    }

    public copyFrom(other: FastBoard): void {
        if (other.pieces.length !== this.pieces.length)
            throw new Error("Boards are different sizes");

        for (let index = 0; index < this.pieces.length; ++index) {
            this.pieces[index] = other.pieces[index];
        }
    }

    public copyFromBoard(board: Board): void {
        if (!this.shape.isEquivalent(board.getShape())) {
            throw new Error("board has a different shape");
        }

        for (let iy = 0; iy < board.getHeight(); ++iy) {
            for (let ix = 0; ix < board.getWidth(); ++ix) {
                const piece = board.containsIndices(ix, iy) ? board.getByIndices(ix, iy) : null;

                let piecePathIndex;
                if (piece === null) {
                    piecePathIndex = 0;
                } else {
                    const pieceSign = (piece.getOwner() == PlayerType.LIGHT ? 1 : -1);
                    piecePathIndex = pieceSign * (piece.getPathIndex() + 1);
                }
                this.set(this.calcTileIndex(ix, iy), piecePathIndex);
            }
        }
    }

    public calcTileIndex(ix: number, iy: number): number {
        return ix + iy * this.width;
    }

    public get(tileIndex: number): number {
        return this.pieces[tileIndex];
    }

    public set(tileIndex: number, piece: number): void {
        this.pieces[tileIndex] = piece;
    }

    public isTileRosette(tileIndex: number): boolean {
        return this.rosetteTiles.includes(tileIndex);
    }

    /**
     * Writes the contents of this board as a string, where each column is placed on a new line.
     */
    toString(columnDelimiter: string = "\n", includeOffBoardTiles: boolean = true): string {
        let boardString = "";

        for (let ix = 0; ix < this.shape.getWidth(); ++ix) {
            if (ix > 0) {
                boardString += columnDelimiter;
            }

            for (let iy = 0; iy < this.shape.getHeight(); ++iy) {
                if (this.shape.containsIndices(ix, iy)) {
                    const fastPiece = this.get(this.calcTileIndex(ix, iy));

                    let piece: Piece | null = null;
                    if (fastPiece !== 0) {
                        piece = new Piece(
                            fastPiece < 0 ? PlayerType.DARK : PlayerType.LIGHT,
                            Math.abs(fastPiece) - 1,
                        );
                    }
                    boardString += Piece.toChar(piece);
                } else if (includeOffBoardTiles) {
                    boardString += " ";
                }
            }
        }
        return boardString;
    }
}
