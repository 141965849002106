
/**
 * The reason that a game was abandoned before it was finished.
 */
export class AbandonReason {

    /**
     * A player left the game before it finished.
     */
    public static readonly PLAYER_LEFT: AbandonReason = new AbandonReason(
        "player_left", "Player Left", true,
    );

    /**
     * An external event caused the game to end before it finished.
     */
    public static readonly EXTERNAL: AbandonReason = new AbandonReason(
        "external", "External", false,
    );

    static values(): AbandonReason[] {
        return [
            AbandonReason.PLAYER_LEFT,
            AbandonReason.EXTERNAL,
        ];
    }

    private readonly id: string;
    private readonly name: string;
    private readonly doesRequirePlayer: boolean;

    constructor(id: string, name: string, doesRequirePlayer: boolean) {
        this.id = id;
        this.name = name;
        this.doesRequirePlayer = doesRequirePlayer;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    requiresPlayer(): boolean {
        return this.doesRequirePlayer;
    }

    static getByID(id: string): AbandonReason {
        for (const reason of AbandonReason.values()) {
            if (reason.getID() === id)
                return reason;
        }
        throw new Error(`Unknown abandon reason ${id}`);
    }
}
