import { PieceProvider } from "@/ts/royalur/rules/PieceProvider";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Piece } from "@/ts/royalur/model/Piece";


/**
 * Provides new instances of, and manipulations to, simple pieces.
 */
export class SimplePieceProvider extends PieceProvider {
    create(owner: PlayerType, pathIndex: number): Piece {
        return new Piece(owner, pathIndex);
    }

    createIntroduced(owner: PlayerType, newPathIndex: number): Piece {
        return new Piece(owner, newPathIndex);
    }

    createMoved(fromPiece: Piece, newPathIndex: number): Piece {
        return new Piece(fromPiece.getOwner(), newPathIndex);
    }
}
