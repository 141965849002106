import { Tile } from "@/ts/royalur/model/Tile";
import { Piece } from "@/ts/royalur/model/Piece";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { BoardShape } from "@/ts/royalur/model/shape/BoardShape";


/**
 * Stores the placement of pieces on the tiles of a Royal Game of Ur board.
 */
export class Board {
    /**
     * The shape of this board.
     */
    private readonly shape: BoardShape;

    /**
     * The number of x-coordinates that exist in this board.
     */
    private readonly width: number;

    /**
     * The number of y-coordinates that exist in this board.
     */
    private readonly height: number;

    /**
     * The pieces on the tiles of this board.
     * Tiles that contain no piece are null.
     */
    private readonly pieces: (Piece | null)[];

    constructor(shapeOrBoard: BoardShape | Board) {
        let board: Board | null = null;
        if (shapeOrBoard instanceof BoardShape) {
            this.shape = shapeOrBoard;
        } else {
            board = shapeOrBoard;
            this.shape = shapeOrBoard.shape;
        }

        this.width = this.shape.getWidth();
        this.height = this.shape.getHeight();

        // Initialise the board.
        this.pieces = [];
        for (let y = 1; y <= this.shape.getHeight(); ++y) {
            for (let x = 1; x <= this.shape.getWidth(); ++x) {
                const tile = new Tile(x, y);
                if (board && this.shape.contains(tile)) {
                    this.pieces.push(board.get(tile));
                } else {
                    this.pieces.push(null);
                }
            }
        }
    }

    /**
     * Creates an exact copy of this board.
     */
    copy(): Board {
        return new Board(this);
    }

    private calcTileIndex(ix: number, iy: number) {
        if (ix < 0 || iy < 0 || ix >= this.width || iy >= this.height) {
            throw new Error(
                "There is no tile at the indices (" + ix + ", " + iy + ")",
            );
        }
        return iy * this.width + ix;
    }

    /**
     * Gets the shape of this board.
     */
    getShape(): BoardShape {
        return this.shape;
    }

    /**
     * Gets the width of the board, which represents
     * the number of x-coordinates in this board.
     */
    getWidth(): number {
        return this.width;
    }

    /**
     * Gets the height of the board, which represents
     * the number of y-coordinates in this board.
     */
    getHeight(): number {
        return this.height;
    }

    /**
     * Determines whether `tile` falls within the bounds of this board.
     */
    contains(tile: Tile) {
        return this.shape.contains(tile);
    }

    /**
     * Determines whether the tile at the indices (`ix`, `iy`),
     * 0-based, falls within the bounds of this board.
     */
    containsIndices(ix: number, iy: number) {
        return this.shape.containsIndices(ix, iy);
    }

    /**
     * Retrieves the piece on `tile`. Returns `null` if there
     * is no piece on the tile.
     */
    get(tile: Tile): Piece | null {
        return this.getByIndices(tile.getXIndex(), tile.getYIndex());
    }

    /**
     * Retrieves the piece on the tile at the indices (`ix`, `iy`),
     * 0-based. Returns `null` if there is no piece on the tile.
     */
    getByIndices(ix: number, iy: number): Piece | null {
        const index = this.calcTileIndex(ix, iy);
        return this.pieces[index];
    }

    /**
     * Sets the piece on `tile` to `piece`. If `piece` is `null`,
     * it removes the piece on the tile. Returns the piece that
     * was previously on the tile, or `null` if there was no
     * piece on the tile.
     */
    set(tile: Tile, piece: Piece | null): Piece | null {
        return this.setByIndices(tile.getXIndex(), tile.getYIndex(), piece);
    }

    /**
     * Sets the piece on the tile at the indices (`ix`, `iy`),
     * 0-based, to the piece `piece`. If `piece` is `null`,
     * it removes any piece on the tile. Returns the piece
     * that was previously on the tile, or `null` if there
     * was no piece on the tile.
     */
    setByIndices(ix: number, iy: number, piece: Piece | null): Piece | null {
        const index = this.calcTileIndex(ix, iy);
        const previous = this.pieces[index];
        this.pieces[index] = piece;
        return previous;
    }

    /**
     * Removes all pieces from this board.
     */
    clear() {
        for (let index = 0; index < this.pieces.length; ++index) {
            this.pieces[index] = null;
        }
    }

    /**
     * Counts the number of pieces that are on the board for `player`.
     */
    countPieces(player: PlayerType): number {
        let totalPieces = 0;
        for (let iy = 0; iy < this.height; ++iy) {
            for (let ix = 0; ix < this.width; ++ix) {
                if (!this.containsIndices(ix, iy))
                    continue;

                const piece = this.getByIndices(ix, iy);
                if (piece != null && piece.getOwner() == player) {
                    totalPieces += 1;
                }
            }
        }
        return totalPieces;
    }

    equals(other: Board): boolean {
        if (!this.shape.equals(other.shape))
            return false;

        for (let iy = 0; iy < this.height; ++iy) {
            for (let ix = 0; ix < this.width; ++ix) {
                if (!this.containsIndices(ix, iy))
                    continue;

                const thisPiece = this.getByIndices(ix, iy);
                const otherPiece = other.getByIndices(ix, iy);
                if (thisPiece !== null && otherPiece !== null) {
                    if (!thisPiece.equals(otherPiece))
                        return false;
                } else if (thisPiece !== null || otherPiece !== null) {
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * Writes the contents of this board as a string, where each column is placed on a new line.
     */
    toString(columnDelimiter: string = "\n", includeOffBoardTiles: boolean = true): string {
        let boardString = "";

        for (let ix = 0; ix < this.shape.getWidth(); ++ix) {
            if (ix > 0) {
                boardString += columnDelimiter;
            }

            for (let iy = 0; iy < this.shape.getHeight(); ++iy) {
                if (this.containsIndices(ix, iy)) {
                    boardString += Piece.toChar(this.getByIndices(ix, iy));
                } else if (includeOffBoardTiles) {
                    boardString += " ";
                }
            }
        }
        return boardString;
    }
}
