import { Roll } from "@/ts/royalur/model/dice/Roll";


export class BasicRoll implements Roll {
    public static ZERO: BasicRoll = new BasicRoll(0);
    public static ONE: BasicRoll = new BasicRoll(1);
    public static TWO: BasicRoll = new BasicRoll(2);
    public static THREE: BasicRoll = new BasicRoll(3);
    public static FOUR: BasicRoll = new BasicRoll(4);

    private readonly rollValue: number;

    constructor(value: number) {
        if (value < 0) {
            throw new Error(
                "Rolls cannot be negative. Initialised with roll of " + value,
            );
        }
        this.rollValue = value;
    }

    value(): number {
        return this.rollValue;
    }

    equals(other: any): boolean {
        if (!other || this.constructor !== other.constructor)
            return false;
        if (!(other instanceof BasicRoll))
            throw new Error("Same constructor, but instanceof returns false");

        return this.rollValue === other.rollValue;
    }

    /**
     * Generates a roll representing a roll of the value {@code value}.
     */
    static of(value: number) {
        switch (value) {
            case 0:
                return BasicRoll.ZERO;
            case 1:
                return BasicRoll.ONE;
            case 2:
                return BasicRoll.TWO;
            case 3:
                return BasicRoll.THREE;
            case 4:
                return BasicRoll.FOUR;
            default:
                return new BasicRoll(value);
        }
    }
}
