import { PathPair } from "@/ts/royalur/model/path/PathPair";
import { Tile } from "@/ts/royalur/model/Tile";
import { PathType } from "@/ts/royalur/model/path/PathType";


/**
 * The paths proposed by Skiriuk for the Royal Game of Ur.
 * <p>
 * Citation: D. Skiriuk, The rules of royal game of ur (2021). Available at
 * <a href="https://skyruk.livejournal.com/231444.html">
 *     https://skyruk.livejournal.com/231444.html
 * </a>.
 */
export class SkiriukPathPair extends PathPair {
    public static lightPath: Tile[] = Tile.createPath(
        [1, 5], [1, 1], [2, 1], [2, 7], [3, 7], [3, 8], [1, 8],
        [1, 7], [2, 7], [2, 0],
    );

    public static darkPath: Tile[] = Tile.createPath(
        [3, 5], [3, 1], [2, 1], [2, 7], [1, 7], [1, 8], [3, 8],
        [3, 7], [2, 7], [2, 0],
    );

    constructor() {
        super(PathType.SKIRIUK.getID(), SkiriukPathPair.lightPath, SkiriukPathPair.darkPath);
    }
}
