import { GameSettings } from "@/ts/royalur/model/GameSettings";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { PlayerStateProvider } from "@/ts/royalur/rules/PlayerStateProvider";
import { PieceProvider } from "@/ts/royalur/rules/PieceProvider";


/**
 * Creates rule sets to match game settings.
 */
export abstract class RuleSetProvider {

    /**
     * Creates the provider of pieces for the given
     * settings and game metadata.
     */
    abstract createPieceProvider(settings: GameSettings): PieceProvider;

    /**
     * Creates the provider of player states for the given
     * settings and game metadata.
     */
    abstract createPlayerStateProvider(settings: GameSettings): PlayerStateProvider;

    /**
     * Creates a rule set to match the given settings and game metadata.
     */
    abstract create(settings: GameSettings): RuleSet;
}
