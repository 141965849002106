import { PathPair } from "@/ts/royalur/model/path/PathPair";
import { Tile } from "@/ts/royalur/model/Tile";
import { PathType } from "@/ts/royalur/model/path/PathType";


/**
 * The paths proposed by Bell for the Royal Game of Ur.
 * <p>
 * Citation: R.C. Bell, Board and Table Games From Many Civilizations,
 * revised ed., Vol. 1 and 2, Dover Publications, Inc., New York, 1979.
 */
export class BellPathPair extends PathPair {
    public static LIGHT_PATH: Tile[] = Tile.createPath(
        [1, 5],
        [1, 1],
        [2, 1],
        [2, 8],
        [1, 8],
        [1, 6],
    );

    public static DARK_PATH: Tile[] = Tile.createPath(
        [3, 5],
        [3, 1],
        [2, 1],
        [2, 8],
        [3, 8],
        [3, 6],
    );

    constructor() {
        super(PathType.BELL.getID(), BellPathPair.LIGHT_PATH, BellPathPair.DARK_PATH);
    }
}
