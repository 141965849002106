import { FastGame } from "@/ts/royalur/rules/simple/fast/FastGame";


/**
 * A function that is used to score game states.
 */
export abstract class UtilityFunction {

    /**
     * Scores the state of the game numerically, where a positive
     * value represents that light is advantaged, and a negative
     * value represents that dark is advantaged.
     */
    abstract scoreGameStateForLight(game: FastGame): number;

    /**
     * Scores the state of the game numerically, where a positive
     * value represents that the current player is advantaged, and
     * a negative value represents that the waiting player is advantaged.
     */
    scoreGame(game: FastGame): number {
        const lightUtility = this.scoreGameStateForLight(game);
        return game.isLightTurn ? lightUtility : -lightUtility;
    }
}
