import { PathPair } from "@/ts/royalur/model/path/PathPair";
import { Tile } from "@/ts/royalur/model/Tile";
import { PathType } from "@/ts/royalur/model/path/PathType";


/**
 * The paths proposed by Murray for the Royal Game of Ur.
 * <p>
 * Citation: H.J.R. Murray, A History of Board-games Other Than Chess,
 * Oxford University Press, Oxford, 1952.
 */
export class MurrayPathPair extends PathPair {
    public static LIGHT_PATH: Tile[] = Tile.createPath(
        [1, 5],
        [1, 1],
        [2, 1],
        [2, 7],
        [3, 7],
        [3, 8],
        [1, 8],
        [1, 7],
        [2, 7],
        [2, 1],
        [3, 1],
        [3, 5],
    );

    public static DARK_PATH: Tile[] = Tile.createPath(
        [3, 5],
        [3, 1],
        [2, 1],
        [2, 7],
        [1, 7],
        [1, 8],
        [3, 8],
        [3, 7],
        [2, 7],
        [2, 1],
        [1, 1],
        [1, 5],
    );

    constructor() {
        super(PathType.MURRAY.getID(), MurrayPathPair.LIGHT_PATH, MurrayPathPair.DARK_PATH);
    }
}
