import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { Piece } from "@/ts/royalur/model/Piece";


/**
 * Provides the manipulation of PlayerStates as a game progresses.
 */
export abstract class PlayerStateProvider {
    protected readonly startingPieceCount: number;

    constructor(startingPieceCount: number) {
        this.startingPieceCount = startingPieceCount;
        if (startingPieceCount <= 0)
            throw new Error(`startingPieces must be at least 1, not ${startingPieceCount}`);
    }

    /**
     * Gets the number of pieces that players start with.
     */
    getStartingPieceCount(): number {
        return this.startingPieceCount;
    }

    /**
     * Generates a player state with the given pieces and score.
     */
    abstract create(player: PlayerType, pieces: number, score: number): PlayerState;

    /**
     * Generates the starting state for the given player.
     */
    abstract createStartingState(player: PlayerType): PlayerState;

    /**
     * Generates a new player state that is a copy of the given playerState,
     * with the given piece introduced to the board.
     */
    abstract applyPieceIntroduced(playerState: PlayerState, piece: Piece): PlayerState;

    /**
     * Generates a new player state that is a copy of the given playerState,
     * with the given piece captured.
     */
    abstract applyPieceCaptured(playerState: PlayerState, piece: Piece): PlayerState;

    /**
     * Generates a new player state that is a copy of the given playerState,
     * with the given piece scored.
     */
    abstract applyPieceScored(playerState: PlayerState, piece: Piece): PlayerState;
}
