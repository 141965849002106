import { PlayerType } from "@/ts/royalur/model/PlayerType";


/**
 * A player state represents the state of a single player at a point in the game.
 * This includes the player's score and number of pieces left to play.
 */
export class PlayerState {
    private readonly player: PlayerType;
    private readonly pieceCount: number;
    private readonly score: number;

    constructor(player: PlayerType, pieceCount: number, score: number) {
        if (pieceCount < 0)
            throw new Error("pieceCount must be positive: " + pieceCount);
        if (score < 0)
            throw new Error("pieceCount must be positive: " + score);

        this.player = player;
        this.pieceCount = pieceCount;
        this.score = score;
    }

    /**
     * Gets the player that this state represents.
     */
    getPlayer(): PlayerType {
        return this.player;
    }

    /**
     * Gets the number of pieces that the player has available
     * to introduce to the board.
     */
    getPieceCount(): number {
        return this.pieceCount;
    }

    /**
     * Gets the number of pieces that the player has taken off the board.
     */
    getScore(): number {
        return this.score;
    }

    equals(other: any) {
        if (!other || this.constructor !== other.constructor)
            return false;
        if (!(other instanceof PlayerState))
            throw new Error("Same constructor, but instanceof returns false");

        return this.player === other.player
            && this.pieceCount === other.pieceCount
            && this.score === other.score;
    }

    toString() {
        let result = this.player.getName() + ": ";
        result += this.pieceCount + (this.pieceCount != 1 ? " Pieces, " : " Piece, ");
        result += this.score + " Score";
        return result;
    }
}
