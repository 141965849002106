import { PlayerStateProvider } from "@/ts/royalur/rules/PlayerStateProvider";
import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Piece } from "@/ts/royalur/model/Piece";


/**
 * Provides new instances of, and manipulations to, simple player states.
 */
export class SimplePlayerStateProvider extends PlayerStateProvider {
    create(player: PlayerType, pieces: number, score: number): PlayerState {
        return new PlayerState(player, pieces, score);
    }

    createStartingState(player: PlayerType): PlayerState {
        return new PlayerState(player, this.startingPieceCount, 0);
    }

    applyPieceCaptured(playerState: PlayerState, _piece: Piece): PlayerState {
        return new PlayerState(
            playerState.getPlayer(),
            playerState.getPieceCount() + 1,
            playerState.getScore(),
        );
    }

    applyPieceIntroduced(playerState: PlayerState, _piece: Piece): PlayerState {
        return new PlayerState(
            playerState.getPlayer(),
            playerState.getPieceCount() - 1,
            playerState.getScore(),
        );
    }

    applyPieceScored(playerState: PlayerState, _piece: Piece): PlayerState {
        return new PlayerState(
            playerState.getPlayer(),
            playerState.getPieceCount(),
            playerState.getScore() + 1,
        );
    }
}
