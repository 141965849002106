import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { BoardShape } from "@/ts/royalur/model/shape/BoardShape";
import { PathPair } from "@/ts/royalur/model/path/PathPair";
import { PieceProvider } from "@/ts/royalur/rules/PieceProvider";
import { PlayerStateProvider } from "@/ts/royalur/rules/PlayerStateProvider";
import { Board } from "@/ts/royalur/model/Board";
import { Move } from "@/ts/royalur/model/Move";
import { GameState } from "@/ts/royalur/rules/state/GameState";
import { WaitingForMoveGameState } from "@/ts/royalur/rules/state/WaitingForMoveGameState";
import { WaitingForRollGameState } from "@/ts/royalur/rules/state/WaitingForRollGameState";
import { DiceFactory } from "@/ts/royalur/model/dice/DiceFactory";
import { GameSettings } from "@/ts/royalur/model/GameSettings";
import { Game } from "@/ts/royalur/Game";
import { Roll } from "@/ts/royalur/model/dice/Roll";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { AbandonReason } from "@/ts/royalur/model/AbandonReason";
import { ResignedGameState } from "@/ts/royalur/rules/state/ResignedGameState";
import { EndGameState } from "@/ts/royalur/rules/state/EndGameState";
import { AbandonedGameState } from "@/ts/royalur/rules/state/AbandonedGameState";
import { PathType } from "@/ts/royalur/model/path/PathType";
import { BoardType } from "@/ts/royalur/model/shape/BoardType";
import { Dice } from "@/ts/royalur/model/dice/Dice";


/**
 * A set of rules that govern the play of a game of the Royal Game of Ur.
 */
export abstract class RuleSet {
    protected readonly settings: GameSettings;
    protected readonly sampleDice: Dice;
    protected readonly pieceProvider: PieceProvider;
    protected readonly playerStateProvider: PlayerStateProvider;

    constructor(
        settings: GameSettings,
        pieceProvider: PieceProvider,
        playerStateProvider: PlayerStateProvider,
    ) {
        if (!settings.getBoardShape().isCompatible(settings.getPaths())) {
            const pathName = PathType.getByID(settings.getPaths().getID()).getName();
            const boardName = BoardType.getByID(settings.getBoardShape().getID()).getName();
            throw new Error(
                `The ${pathName} path is not compatible with the ${boardName} board shape`,
            );
        }
        this.settings = settings;
        this.sampleDice = settings.getDice().createDice();
        this.pieceProvider = pieceProvider;
        this.playerStateProvider = playerStateProvider;
    }

    /**
     * Get the settings used for this rule set.
     * @return The settings used for this rule set.
     */
    getSettings(): GameSettings {
        return this.settings;
    }

    /**
     * Gets the shape of the board used in this rule set.
     */
    getBoardShape(): BoardShape {
        return this.settings.getBoardShape();
    }

    /**
     * Gets the paths that the players must take around the board.
     */
    getPaths(): PathPair {
        return this.settings.getPaths();
    }

    /**
     * Gets the dice that are used to generate dice rolls.
     */
    getDiceFactory(): DiceFactory {
        return this.settings.getDice();
    }

    /**
     * Gets a sample dice that can be used for parsing, but should
     * not be used for running games.
     * @return A sample dice that can be used for parsing.
     */
    getSampleDice(): Dice {
        return this.sampleDice;
    }

    /**
     * Gets whether rosettes are considered safe squares in this rule set.
     */
    areRosettesSafe(): boolean {
        return this.settings.areRosettesSafe();
    }

    /**
     * Gets whether landing on rosette tiles grants an additional roll.
     */
    doRosettesGrantExtraRolls(): boolean {
        return this.settings.doRosettesGrantExtraRolls();
    }

    /**
     * Gets whether capturing a piece grants an additional roll.
     */
    doCapturesGrantExtraRolls(): boolean {
        return this.settings.doCapturesGrantExtraRolls();
    }

    /**
     * Gets the provider of piece manipulations.
     */
    getPieceProvider(): PieceProvider {
        return this.pieceProvider;
    }

    /**
     * Gets the provider of player state manipulations.
     */
    getPlayerStateProvider(): PlayerStateProvider {
        return this.playerStateProvider;
    }

    /**
     * Generates a new game using these rules.
     */
    generateGame(): Game {
        return new Game(this);
    }

    /**
     * Generates the initial state for a game.
     */
    abstract generateInitialGameState(): GameState;

    /**
     * Finds all available moves from the current state of the board and the player,
     * and the roll that they made of the dice.
     */
    abstract findAvailableMoves(board: Board, player: PlayerState, roll: Roll): Move[];

    /**
     * Applies `roll` to `state` to generate the new state of the game. Multiple
     * game states may be returned to include information game states for maintaining
     * history. However, the latest or highest-index game state will represent the
     * state of the game after the move was made.
     */
    abstract applyRoll(
        state: WaitingForRollGameState,
        roll: Roll,
    ): GameState[];

    /**
     * Applies `move` to `state` to generate the new state of the game. Multiple
     * game states may be returned to include information game states for maintaining
     * history. However, the latest or highest-index game state will represent the
     * state of the game after the move was made.
     * <p>
     * This method does not check that the given move is a valid move
     * from the current game state.
     */
    abstract applyMove(
        state: WaitingForMoveGameState,
        move: Move
    ): GameState[];

    /**
     * Applies a resignation by the given player to generate the new state of the game.
     */
    applyResign(state: GameState, player: PlayerType): GameState[] {
        const board = state.getBoard();
        const light = state.getLightPlayer();
        const dark = state.getDarkPlayer();
        const winner = player.getOtherPlayer();
        return [
            new ResignedGameState(board, light, dark, player),
            new EndGameState(board, light, dark, winner),
        ];
    }

    /**
     * Applies an abandonment of the game due to the given reason. If the abandonment was
     * caused by a player, then player should be provided.
     */
    applyAbandon(
        state: GameState,
        reason: AbandonReason,
        player: PlayerType | null,
    ): GameState[] {
        const board = state.getBoard();
        const light = state.getLightPlayer();
        const dark = state.getDarkPlayer();
        const winner = (player != null ? player.getOtherPlayer() : null);
        return [
            new AbandonedGameState(board, light, dark, reason, player),
            new EndGameState(board, light, dark, winner),
        ];
    }

    /**
     * Selects only the states that are required to reproduce
     * exactly what happened in a game using this rule set.
     * This is used to reduce the amount of information saved
     * during serialisation.
     */
    abstract selectLandmarkStates(states: GameState[]): GameState[];
}
