import { BoardShape } from "@/ts/royalur/model/shape/BoardShape";
import { AsebBoardShape } from "@/ts/royalur/model/shape/AsebBoardShape";
import { StandardBoardShape } from "@/ts/royalur/model/shape/StandardBoardShape";
import { BoardShapeFactory } from "@/ts/royalur/model/shape/BoardShapeFactory";


/**
 * The type of board to use in a game.
 */
export class BoardType implements BoardShapeFactory {

    /**
     * The simple board shape.
     */
    static readonly STANDARD: BoardType = new BoardType(
        "standard", "Standard", () => new StandardBoardShape(),
    );

    /**
     * The Aseb board shape.
     */
    static readonly ASEB: BoardType = new BoardType(
        "aseb", "Aseb", () => new AsebBoardShape(),
    );

    static values(): BoardType[] {
        return [
            BoardType.STANDARD,
            BoardType.ASEB,
        ];
    }

    private static createParsingMap(): Record<string, BoardShapeFactory> {
        const map: Record<string, BoardShapeFactory> = {};
        map["Standard"] = BoardType.STANDARD;
        map["Aseb"] = BoardType.ASEB;

        for (const value of BoardType.values()) {
            map[value.id] = value;
        }
        return Object.freeze(map);
    }

    static readonly PARSING_MAP: Record<string, BoardShapeFactory> = BoardType.createParsingMap();

    private readonly id: string;
    private readonly name: string;
    private readonly createFn: () => BoardShape;

    constructor(id: string, name: string, createFn: () => BoardShape) {
        this.id = id;
        this.name = name;
        this.createFn = createFn;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    createBoardShape(): BoardShape {
        return this.createFn();
    }

    static getByID(id: string): BoardType {
        for (const boardType of BoardType.values()) {
            if (boardType.id === id)
                return boardType;
        }
        throw new Error(`Unknown board type ${id}`);
    }

    static getByIDOrNull(id: string | null): BoardType | null {
        if (id === null)
            return null;

        for (const boardType of BoardType.values()) {
            if (boardType.id === id)
                return boardType;
        }
        return null;
    }
}
