import { DiceFactory } from "@/ts/royalur/model/dice/DiceFactory";
import { Roll } from "@/ts/royalur/model/dice/Roll";
import { BinaryDice } from "@/ts/royalur/model/dice/BinaryDice";
import { Dice } from "@/ts/royalur/model/dice/Dice";
import { BinaryDice0AsMax } from "@/ts/royalur/model/dice/BinaryDice0AsMax";


/**
 * The type of dice to be used in a game.
 */
export class DiceType implements DiceFactory {

    /**
     * Represents rolling four binary die and counting the number
     * of ones that were rolled.
     */
    static readonly FOUR_BINARY: DiceType = new DiceType(
        "four_binary",
        "Four Binary",
        "Four binary die (default)",
        4,
        () => new BinaryDice(
            DiceType.FOUR_BINARY.id,
            DiceType.FOUR_BINARY.dieCount,
        ),
    );

    /**
     * Represents rolling three binary die and counting the number
     * of ones that were rolled. If no ones are rolled, then a value
     * of four is given.
     */
    static readonly THREE_BINARY_0EQ4: DiceType = new DiceType(
        "three_binary_0eq4",
        "Three Binary 0 Equals 4",
        "Three binary die where 0 becomes 4",
        3,
        () => new BinaryDice0AsMax(
            DiceType.THREE_BINARY_0EQ4.id,
            DiceType.THREE_BINARY_0EQ4.dieCount,
        ),
    );

    static values(): DiceType[] {
        return [
            DiceType.FOUR_BINARY,
            DiceType.THREE_BINARY_0EQ4,
        ];
    }

    private static createParsingMap(): Record<string, DiceFactory> {
        const map: Record<string, DiceFactory> = {};
        map["FourBinary"] = DiceType.FOUR_BINARY;
        map["fourbinary"] = DiceType.FOUR_BINARY;
        map["ThreeBinary0Max"] = DiceType.THREE_BINARY_0EQ4;
        map["threebinary0max"] = DiceType.THREE_BINARY_0EQ4;

        for (const value of DiceType.values()) {
            map[value.id] = value;
        }
        return Object.freeze(map);
    }

    static readonly PARSING_MAP: Record<string, DiceFactory> = DiceType.createParsingMap();

    private readonly id: string;
    private readonly name: string;
    private readonly desc: string;
    private readonly dieCount: number;
    private readonly createFn: () => Dice;

    constructor(
        id: string,
        name: string,
        desc: string,
        dieCount: number,
        createFn: () => Dice,
    ) {
        this.id = id;
        this.name = name;
        this.desc = desc;
        this.dieCount = dieCount;
        this.createFn = createFn;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getDesc(): string {
        return this.desc;
    }

    getDieCount(): number {
        return this.dieCount;
    }

    createDice(): Dice {
        return this.createFn();
    }

    createRoll(value: number): Roll {
        return this.createDice().generateRoll(value);
    }

    isEquivalent(other: DiceFactory): boolean {
        return other === this;
    }

    static getByID(id: string): DiceType {
        for (const diceType of DiceType.values()) {
            if (diceType.id === id)
                return diceType;
        }
        throw new Error(`Unknown dice type ${id}`);
    }

    static getByIDOrNull(id: string | null): DiceType | null {
        if (id === null)
            return null;

        for (const diceType of DiceType.values()) {
            if (diceType.id === id)
                return diceType;
        }
        return null;
    }
}
